import { Box, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { i18n } = useTranslation();

  return (
    <Box maxW="1240px" margin="0 auto" bg="#fff" p="2rem">
      {i18n.language === "zh" && (
        <Box>
          <Heading pb="2rem" textAlign="center">
            用户隐私协议
          </Heading>
          <Text>
            感谢您访问我们的网站。我们非常重视您的隐私，并承诺保护您的个人信息。本隐私协议详细说明了我们如何收集、使用、存储和保护您的个人信息。请您仔细阅读以下内容，以了解我们的隐私保护措施。
          </Text>
          <Heading size="md" py="1rem">
            一、我们收集的信息
          </Heading>
          <Text>在您使用我们的网站时，我们可能会收集以下类型的信息：</Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>个人身份信息</strong>
              ：包括您的姓名、电子邮件地址、电话号码、邮寄地址等。
            </li>
            <li>
              <strong>账户信息</strong>
              ：包括用户名、密码等，用于访问我们网站的账户。
            </li>
            <li>
              <strong>交易信息</strong>：包括购买记录、支付信息、物流信息等。
            </li>
            <li>
              <strong>技术信息</strong>
              ：包括您的IP地址、浏览器类型、操作系统、访问时间、访问页面等。
            </li>
            <li>
              <strong>其他信息</strong>
              ：您在使用我们的服务过程中提供的其他信息，如反馈、评论等。
            </li>
          </ol>
          <Heading size="md" py="1rem">
            二、我们如何使用信息
          </Heading>
          <Text>我们收集的信息主要用于以下目的：</Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>提供服务</strong>
              ：处理您的订单、交付产品和服务、处理付款等。
            </li>
            <li>
              <strong>客户支持</strong>
              ：回应您的询问、解决您的问题、提供客户支持服务。
            </li>
            <li>
              <strong>个性化体验</strong>
              ：根据您的偏好和兴趣提供个性化的内容和推荐。
            </li>
            <li>
              <strong>改进服务</strong>：分析使用数据，改进我们的网站和服务。
            </li>
            <li>
              <strong>市场推广</strong>
              ：向您发送促销信息、优惠券、新闻通讯等，前提是您已同意接收这些信息。
            </li>
            <li>
              <strong>法律要求</strong>：遵守法律法规的要求，履行法律义务。
            </li>
          </ol>
          <Heading size="md" py="1rem">
            三、信息的共享与披露
          </Heading>
          <Text>
            我们不会向第三方出售、出租或分享您的个人信息，除非在以下情况下：
          </Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>获得您的同意</strong>
              ：在获得您的明确同意后，我们可以与第三方共享您的信息。
            </li>
            <li>
              <strong>合作伙伴</strong>
              ：我们可能会与可信赖的合作伙伴共享信息，以便提供更好的服务。
            </li>
            <li>
              <strong>法律要求</strong>
              ：在法律要求的情况下，或为了保护我们的合法权益，我们可能会披露您的信息。
            </li>
            <li>
              <strong>业务转让</strong>
              ：在业务合并、收购、重组等情况下，您的信息可能会作为业务资产转移。
            </li>
          </ol>
          <Heading size="md" py="1rem">
            四、信息的存储与安全
          </Heading>
          <Text>
            我们采取合理的技术和管理措施保护您的个人信息，防止信息丢失、滥用、未经授权的访问或披露。尽管我们尽力保护您的信息，但无法保证信息传输的绝对安全性。
          </Text>
          <Heading size="md" py="1rem">
            五、您的权利
          </Heading>
          <Text>
            您有权访问、更正、更新或删除您的个人信息。您可以随时联系我们的客户支持，行使您的权利。您也有权拒绝接收我们的市场推广信息，您可以通过电子邮件中的退订链接进行退订。
          </Text>
          <Heading size="md" py="1rem">
            六、儿童隐私
          </Heading>
          <Text>
            我们的网站和服务不面向13岁以下的儿童，我们不会有意收集13岁以下儿童的个人信息。如果您发现我们未经父母同意收集了13岁以下儿童的信息，请联系我们，我们将尽快删除相关信息。
          </Text>
          <Heading size="md" py="1rem">
            七、隐私协议的变更
          </Heading>
          <Text>
            我们可能会不时更新本隐私协议，以反映我们的信息处理实践的变化。我们会在网站上发布更新后的隐私协议，并在生效前通过电子邮件或其他显著方式通知您。
          </Text>
          <Heading size="md" py="1rem">
            八、联系我们
          </Heading>
          <Text>
            如果您对本隐私协议有任何疑问或需要进一步的信息，请通过以下方式联系我们：
          </Text>
          <Text>
            <strong>电子邮件</strong>：info@yuanlite.com
          </Text>
          <Text>&nbsp;</Text>
          <Text>&nbsp;</Text>
        </Box>
      )}
      {i18n.language === "en" && (
        <Box>
          <Heading pb="2rem" textAlign="center">
            Privacy Policy
          </Heading>
          <Text>
            Thank you for visiting our website. We highly value your privacy and
            are committed to protecting your personal information. This Privacy
            Policy explains how we collect, use, store, and protect your
            personal information. Please read the following carefully to
            understand our privacy practices.
          </Text>
          <Heading size="md" py="1rem">
            1. Information We Collect
          </Heading>
          <Text>
            When you use our website, we may collect the following types of
            information:
          </Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>Personal Identification Information</strong>: Such as your
              name, email address, phone number, and mailing address.
            </li>
            <li>
              <strong>Account Information</strong>: Such as your username and
              password for accessing your account on our website.
            </li>
            <li>
              <strong>Transaction Information</strong>: Such as purchase
              records, payment information, and logistics information.
            </li>
            <li>
              <strong>Technical Information</strong>: Such as your IP address,
              browser type, operating system, access times, and pages visited.
            </li>
            <li>
              <strong>Other Information</strong>: Any other information you
              provide to us during your use of our services, such as feedback
              and comments.
            </li>
          </ol>
          <Heading size="md" py="1rem">
            2. How We Use Information
          </Heading>
          <Text>
            The information we collect is primarily used for the following
            purposes:
          </Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>Providing Services</strong>: Processing your orders,
              delivering products and services, and handling payments.
            </li>
            <li>
              <strong>Customer Support</strong>: Responding to your inquiries,
              resolving issues, and providing customer support services.
            </li>
            <li>
              <strong>Personalized Experience</strong>: Providing personalized
              content and recommendations based on your preferences and
              interests.
            </li>
            <li>
              <strong>Improving Services</strong>: Analyzing usage data to
              improve our website and services.
            </li>
            <li>
              <strong>Marketing</strong>: Sending you promotional information,
              coupons, newsletters, and other marketing materials, provided you
              have consented to receive such communications.
            </li>
            <li>
              <strong>Legal Compliance</strong>: Complying with legal and
              regulatory requirements and fulfilling our legal obligations.
            </li>
          </ol>
          <Heading size="md" py="1rem">
            3. Sharing and Disclosure of Information
          </Heading>
          <Text>
            We do not sell, rent, or share your personal information with third
            parties except in the following circumstances:
          </Text>
          <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
            <li>
              <strong>With Your Consent</strong>: We may share your information
              with third parties when we have your explicit consent to do so.
            </li>
            <li>
              <strong>Trusted Partners</strong>: We may share information with
              trusted partners to provide better services.
            </li>
            <li>
              <strong>Legal Requirements</strong>: We may disclose your
              information to comply with legal requirements or to protect our
              legal rights.
            </li>
            <li>
              <strong>Business Transfers</strong>: In the event of a merger,
              acquisition, or reorganization, your information may be
              transferred as part of the business assets.
            </li>
          </ol>
          <Heading size="md" py="1rem">
            4. Storage and Security of Information
          </Heading>
          <Text>
            We take reasonable technical and administrative measures to protect
            your personal information from loss, misuse, unauthorized access, or
            disclosure. While we strive to protect your information, we cannot
            guarantee absolute security of information transmission.
          </Text>
          <Heading size="md" py="1rem">
            5. Your Rights
          </Heading>
          <Text>
            You have the right to access, correct, update, or delete your
            personal information. You can contact our customer support at any
            time to exercise your rights. You also have the right to opt-out of
            receiving our marketing communications by using the unsubscribe link
            in our emails.
          </Text>
          <Heading size="md" py="1rem">
            6. Children's Privacy
          </Heading>
          <Text>
            Our website and services are not directed to children under 13 years
            of age, and we do not knowingly collect personal information from
            children under 13. If we become aware that we have collected
            personal information from a child under 13 without parental consent,
            we will take steps to delete such information promptly.
          </Text>
          <Heading size="md" py="1rem">
            7. Changes to the Privacy Policy
          </Heading>
          <Text>
            We may update this Privacy Policy from time to time to reflect
            changes in our information practices. We will post the updated
            Privacy Policy on our website and notify you by email or other
            prominent means before the changes take effect.
          </Text>
          <Heading size="md" py="1rem">
            8. Contact Us
          </Heading>
          <Text>
            If you have any questions or require further information about this
            Privacy Policy, please contact us at:
          </Text>
          <Text>
            <strong>Email</strong>: info@yuanlite.com
          </Text>
          <Text>&nbsp;</Text>
          <Text>&nbsp;</Text>
        </Box>
      )}
    </Box>
  );
};

export default Privacy;
