import React, { useState } from "react";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { canAccess } from "../../../utils/auth";
import PublishList from "../../Requirements/PublishList";
import ApplyList from "../../Requirements/MyApplyList";
import Notice from "../../Notice";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const WorkbenchRequirements: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const [refreshKey, setRefreshKey] = useState(0);
  const { t } = useTranslation();
  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.project-marketplace",
      path: "/workbench/project-marketplace",
    },
  ];
  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" pb="0">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.project-marketplace")}
        </Text>
      </Box>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList bg={"#fff"} p="1rem">
          <Tab>{t("requirements.tab.publish")}</Tab>
          <Tab>{t("requirements.tab.apply")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0">
            <Text bg={"#fff"} p="1rem" pt="0" mb="1rem">
              {t("requirements.tab.publish-sub-title")}
            </Text>

            {canAccess() ? (
              <PublishList onRefresh={handleRefresh}></PublishList>
            ) : (
              <Notice
                titleStr={t("common.notice.title")}
                btnStr={t("common.notice.btn")}
              ></Notice>
            )}
          </TabPanel>
          <TabPanel p="0">
            <Text bg={"#fff"} p="1rem" pt="0" mb="1rem">
              {t("requirements.tab.apply-sub-title")}
            </Text>

            {canAccess() ? (
              <ApplyList></ApplyList>
            ) : (
              <Notice
                titleStr={t("common.notice.title")}
                btnStr={t("common.notice.btn")}
              ></Notice>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default WorkbenchRequirements;
