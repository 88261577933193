import React from "react";
import { Tag, TagLabel, Wrap, WrapItem, Text } from "@chakra-ui/react";

interface TagSelectorProps {
  name?: string;
  items: string[];
  selectedItems: string[];
  selectedColorScheme?: string;
  setSelectedItems: (items: string[]) => void;
}

const TagSelector: React.FC<TagSelectorProps> = ({
  name,
  items,
  selectedItems,
  selectedColorScheme = "blue",
  setSelectedItems,
}) => {
  const toggleItem = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <Wrap spacing={2} mb={2}>
      {!!name && (
        <WrapItem>
          <Text fontSize="lg" fontWeight="bold" minW="100px" textAlign="left">
            {name}:
          </Text>
        </WrapItem>
      )}

      {items.map((item) => (
        <WrapItem key={item}>
          <Tag
            key={item}
            size="lg"
            onClick={() => toggleItem(item)}
            cursor="pointer"
            variant="subtle"
            colorScheme={
              selectedItems.includes(item) ? selectedColorScheme : "gray"
            }
          >
            <TagLabel>{item}</TagLabel>
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default TagSelector;
