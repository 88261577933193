import {
  AspectRatio,
  Box,
  Text,
  Image,
  Tag,
  Heading,
  Link,
} from "@chakra-ui/react";
import { FiTag, FiUser } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Case } from "../../../models/case";
import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

interface CaseProps {
  caseInfo: Case;
}

const CaseCard: React.FC<CaseProps> = ({ caseInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const MotionBox = motion(Box);
  const _renderTag = (row: string, index: number) => {
    return (
      <Tag
        size="md"
        variant="solid"
        ml="0.5rem"
        my={{ base: "0.1rem", xl: 0 }}
        key={index}
      >
        {row}
      </Tag>
    );
  };

  const _renderTags = (tags: string[]) => {
    //多个tag无法全部展示，只展示前两个
    const _tags = tags.slice(0, 2);
    return (
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {_tags!.map((row, index) => {
          return _renderTag(row, index);
        })}
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      shadow={"md"}
      transition="transform 0.3s"
      _hover={{
        boxShadow: "lg",
        rounded: "md",
        cursor: "pointer",
        transform: "translateY(-6px)",
      }}
      bg="white"
      minH={{ base: "360px", md: "300px" }}
      mb="1rem"
      position="relative"
      borderRadius="5px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      css={{
        perspective: "1000px",
      }}
      onClick={() => navigate(`/articles/cases/details/${caseInfo.id}`)}
    >
      <MotionBox
        width="100%"
        borderRadius="5px"
        p="0.5rem"
        position="absolute"
        bg="white"
        backfacevisibility="hidden"
        transition="transform 0.3s"
        zIndex={isHovered ? 1 : 100}
        transform={isHovered ? "rotateY(-180deg)" : "rotateY(0deg)"}
      >
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <Box w={{ base: "100%" }}>
            <Box w="100%" h="100%" display="flex" alignItems="center">
              <AspectRatio w="100%" ratio={3 / 2}>
                <Image
                  borderRadius="8px"
                  src={caseInfo.images[0]?.url}
                  objectFit="cover"
                />
              </AspectRatio>
            </Box>
          </Box>
          <Box w={{ base: "100%" }}>
            <Box>
              <Heading
                my="0.5rem"
                minH="48px"
                size="md"
                color="teal"
                textAlign="left"
                noOfLines={{ base: 2 }}
                _hover={{
                  textDecoration: "underline",
                }}
              >
                {caseInfo.title}
              </Heading>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                py={{ base: "0.3rem" }}
                color="gray.500"
              >
                <Box display="flex" alignItems="center">
                  <FiTag fontSize="1rem" />
                  <Text color="gray.500"> {t("case.list.tags")}:</Text>
                </Box>
                {_renderTags(caseInfo.tags)}
                {/* <Box display="flex" flexWrap="wrap" alignItems="center">
                  {caseInfo.tags!.map((row, index) => {
                    return _renderTag(row, index);
                  })}
                </Box> */}
              </Box>
              <Box
                justifyContent="space-between"
                fontSize="0.9rem"
                color="gray.500"
              >
                <Box
                  justifyContent="flex-end"
                  color="gray.500"
                  fontSize="0.9rem"
                >
                  {/* <Box
                    display="flex"
                    alignItems="center"
                    pr="0.5rem"
                    pb={{ base: "0.3rem" }}
                  >
                    <FaRegCalendarAlt />
                    <Text pl="0.2rem">{t("case.list.posted-at")}:</Text>
                    <Text pl="0.2rem">
                      {caseInfo.createdDate.replace("T", " ")}
                    </Text>
                  </Box> */}
                  <Box
                    display="flex"
                    alignItems="center"
                    pb={{ base: "0.3rem" }}
                  >
                    <FiUser />
                    <Text pl="0.2rem">{t("case.list.owner")}:</Text>
                    <Link
                      href={"/organizations/details/" + caseInfo.organizationId}
                      target="_blank"
                    >
                      <Text color="teal" pl="0.2rem">
                        {caseInfo.ownedBy}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </MotionBox>
      <MotionBox
        borderRadius="5px"
        p="0.5rem"
        width="100%"
        minH="310px"
        position="absolute"
        bg="white"
        backfacevisibility="hidden"
        transition="transform 0.3s"
        zIndex={isHovered ? 100 : 1}
        transform={isHovered ? "rotateY(0deg)" : "rotateY(180deg)"}
      >
        {/* Back Side */}
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bg="#fff"
          borderRadius="8px"
          p="1rem"
        >
          <Text noOfLines={11}>{caseInfo.backgroundDesc}</Text>
        </Box>
      </MotionBox>
    </Box>
  );
};

export default CaseCard;
