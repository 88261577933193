import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ReleaseDIgitalWorkforceClientItem,
} from "../../../../../models/releaseDigitalWorkforce";
import { releaseDigitalWorkforceClientValidationSchema } from "../../../../../utils/validation";
import { Options } from "../../../../../models/common";
import { createReleaseDigitalWorkforceClient } from "../../../../../services/releaseDigitalWorkforce";
import timezones from "../../../../../assets/data/timezone.json";
import { Select as ChakraSelect } from "chakra-react-select";
import { arrayToOptions } from "../../../../../utils/common";


interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: ReleaseDIgitalWorkforceClientItem;
  onSubmit: () => void;
}

const EditReleaseDigitalWorkforceClient: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [selectedTimezone, setSelectedTimezone] = useState<Options | null>();
  useEffect(() => {
    if(initialValues.timeZone) {
      setSelectedTimezone({
        label: initialValues.timeZone,
        value: initialValues.timeZone
      })
    } else {
      setSelectedTimezone(null)
    }
  }, [initialValues]);


  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const editData: any = {
      ...values
    };
    let opt = "create";
    if (!isEmpty(values.id)) {
      editData.id = values.id;
      opt = "update";
    }
    createReleaseDigitalWorkforceClient(editData)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {!!initialValues.id
            ? t("release-digital-workforce.client.edit")
            : t("release-digital-workforce.client.create")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={releaseDigitalWorkforceClientValidationSchema
          }
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Box my="0.5rem">
                  <Field name="clientName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.clientName && form.touched.clientName}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.clientName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                <Field name="clientDescription">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.clientDescription &&
                          form.touched.clientDescription
                        }
                      >
                        <FormLabel>
                          {t("release-digital-workforce.function.desc")}:
                        </FormLabel>
                        <Textarea {...field} minH="10rem" />
                        <FormErrorMessage>
                          {form.errors.clientDescription}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="vhost">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.vhost && form.touched.vhost}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.vhost")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.vhost}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="queueName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.queueName && form.touched.queueName}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.queueName")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.queueName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="logVhost">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.logVhost && form.touched.logVhost}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.logVhost")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.logVhost}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="logQueueName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.logQueueName && form.touched.logQueueName}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.logQueueName")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.logQueueName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="timeZone">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.timeZone && form.touched.timeZone}
                      >
                        <FormLabel>
                          {t("release-digital-workforce.client.timezone")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          options={arrayToOptions(timezones)}
                          value={selectedTimezone}
                          onChange={(selectedOptions) => {
                            setSelectedTimezone(selectedOptions as Options);
                            form.setFieldValue(field.name, selectedOptions?.value);
                          }}
                        />
                        <FormErrorMessage>{form.errors.timeZone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{ md: "flex-end" }}
                >
                  <Button
                    minW="8rem"
                    size="md"
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    onClick={() => {
                      console.log(332);
                      props.submitForm();
                    }}
                  >
                    {t("common.btn.submit")}
                  </Button>
                  <Button
                    minW="8rem"
                    mr="1rem"
                    size="md"
                    colorScheme="gray"
                    isLoading={props.isSubmitting}
                    onClick={onClose}
                  >
                    {t("common.btn.cancel")}
                  </Button>

                </Box>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditReleaseDigitalWorkforceClient;
