import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserRow } from "../../models/user";
import { userList } from "../../services/authService";

const UserManagement = () => {
  const { t } = useTranslation();
  const textColor = useColorModeValue("gray.700", "white");
  const [tableList, setTableList] = useState<UserRow[]>([]);
  useEffect(() => {
    userList().then((res) => {
      setTableList(res);
    });
  }, []);

  const tableFiled = [
    t("user.list.email"),
    t("user.list.organization-name"),
    t("user.list.organization-size"),
    t("user.list.description"),
  ];

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td pl="0px" {...extraProps}>
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: UserRow, index: number) => {
    return (
      <Tr key={index}>
        {_renderCell(row.email, "sm", "bold")}
        {_renderCell(row.organizationName, "sm", "normal", { minW: "120px" })}
        {_renderCell(row.organizationNumber, "sm", "normal", { minW: "120px" })}
        {_renderCell(row.organizationDescription, "sm", "normal", {
          minW: "400px",
        })}
        {/* <Td>
          <Button p="0px" bg="transparent" variant="no-hover">
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              Edit
            </Text>
          </Button>
        </Td> */}
      </Tr>
    );
  };

  return (
    <Box
      maxW="1240px"
      margin="0 auto"
      overflowX={{ base: "scroll", md: "hidden" }}
      bg={"#fff"}
      p="1rem"
    >
      <Box pb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("user.list.header")}
        </Text>
      </Box>
      <Box overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: UserRow, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default UserManagement;
