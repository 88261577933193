import instance from "./interceptor";

export const getCaseList = async (organizationId: string) => {
  try {
    const response = await instance.get(
      `/api/case?organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
export const getCaseDetail = async (caseId: string) => {
  try {
    const response = await instance.get(`/api/case/${caseId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteCase = async (id: string) => {
  try {
    const response = await instance.delete(`/api/case?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateCase = async (data: any) => {
  try {
    const response = await instance.post(`/api/case/upsert`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
