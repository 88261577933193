import { ReleaseDIgitalWorkforceFunction } from "./releaseDigitalWorkforce";

export interface DigitalWorkforceRow {
  id?: string;
  customizedOrganizationId?: string;
  name: string;
  domains: string[];
  responsibilities: string;
  type: number;
  status: number;
  subscribedOrganizations?: string[];
  subscribeStatus?: boolean;
  useCases?: string[];
  platforms?: string[];
  groups?: ReleaseDIgitalWorkforceFunction[];
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  subscribedDigitalWorkforceId?: string;
  executionCount: number;
  totalExecutionTime: number;
}

export interface SubscribedOrganization {
  id: string;
  name: string;
  number: number;
  description: string;
  digitalWorkforces: DigitalWorkforceRow[];
}

export interface SubscribedDigitalWorkforce {
  map(
    arg0: (
      item: SubscribedDigitalWorkforce
    ) => import("react/jsx-runtime").JSX.Element
  ): import("react").ReactNode | Iterable<import("react").ReactNode>;
  id: string;
  subscribedUserId: string;
  activityStatus: number;
  lastActiveTime: string;
  subscribedWorkforceFunctionsGroups: SubscribedWorkforceFunctionsGroup[];
  vhost: string;
  queueName: string;
  logVhost: string;
  logQueueName: string;
  activeWorkforceGroupTasks: WorkforceGroupTask;
}
export interface SubscribedWorkforceFunctionsGroup {
  id: string;
  groupName: string;
  groupDescription: string;
  subscribedWorkforceId: string;
  executeType: number;
  cronStr: string;
  timeZoneId: string;
  nextRun: string;
  status: number;
  subscribedDate: string;
  exprireDate: string;
  customConfig: string;
  groupActivityStatus: number;
  activeTaskGroup: WorkforceGroupTask;
  subscribedWorkforceFunctions: SubscribedWorkforceFunction[];
}
export interface SubscribedWorkforceFunction {
  id: string;
  functionName: string;
  functionDescription: string;
  sequence: number;
  continueOnError: number;
  command: string;
  subscribedWorkforceFunctionGroupId: string;
  activeTasks: ActiveWorkforceTasks;
}

export interface ActiveWorkforceTasks {
  taskId: string;
  subscribedFunctionId: string;
  subscribedWorkforceId: string;
  functionName: string;
  functionDescription: string;
  startTime: string;
  finishTime: string;
  taskStatus: TaskStatus;
  createdBy: string;
  createdTime: string;
}
export interface StopTaskRequest {
  taskGroupId: string;
  subScribedWorkforceFunctionGroupId: string;
}

export interface WorkforceGroupTask {
  taskGroupId: string;
  subscribedWorkforceFunctionGroupId: string;
  subscribedWorkforceId: string;
  startTime: string;
  finishTime: string;
  status: number;
  createdBy: string;
  createdTime: string;
}

export enum Status {
  Offline = -1,
  Idle = 0,
  Working = 1,
  Failed = 3,
  Cancel = 4,
}
export enum TaskStatus {
  Pending = 0,
  Working = 1,
  Success = 2,
  Failed = 3,
  Cancel = 4,
}

export enum CotinueOnError {
  False = 0,
  True = 1,
}

export interface DigitalWorkforceConf {
  id?: string;
  name: string;
  domains: string[];
  responsibilities: string;
  tasks: string[];
  role: {
    id: string;
    groups: string[];
  };
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
}
