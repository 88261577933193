import { useState, useEffect } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { active } from "../../../services/authService";
import CountdownTimer from "../../../components/CountdownTimer";
const Active = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (!id || id.trim() === "") {
      setIsInvalid(true);
      return;
    } else {
      activeUser();
    }
  }, [id]);

  const activeUser = () => {
    active(id!)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((error) => {});
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      {isInvalid ? (
        <>
          <Box display="inline-block">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={"red.500"}
              rounded={"50px"}
              w={"55px"}
              h={"55px"}
              textAlign="center"
            >
              <CloseIcon boxSize={"20px"} color={"white"} />
            </Flex>
          </Box>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            The link for the current page is invalid, and the account activation
            has failed.
          </Heading>
        </>
      ) : (
        <>
          <Box>
            {isLoading ? (
              <>
                <Box>
                  <Heading as="h2" size="xl" mt={6} mb={2}>
                    Loading ...
                  </Heading>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
                  <Heading as="h2" size="xl" mt={6} mb={2}>
                    Your account has been activated!
                  </Heading>
                  <Text mt={4} color={"gray.500"}>
                    Redirecting to login page in
                    <CountdownTimer initialCount={5} redirectUrl="/login" />
                    seconds...
                  </Text>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Active;
