import instance from "./interceptor";
export const messageList = async () => {
  try {
    const response = await instance.get(`/api/messages/display`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
