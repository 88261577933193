import { Box, Button, Heading, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForbiddenPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      maxW="1240px"
      margin="0 auto"
      textAlign="center"
      py={10}
      px={6}
      mt={10}
      bgColor="white"
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, red.400, red.600)"
        backgroundClip="text"
      >
        403
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {t("403.title")}
      </Text>
      <Text color={"gray.500"} mb={6}>
        {t("403.sub-title")}
      </Text>

      <Button
        colorScheme="red"
        bgGradient="linear(to-r, red.400, red.500, red.600)"
        color="white"
        variant="solid"
      >
        <Link
          as={RouterLink}
          to={"/"}
          display="flex"
          alignItems="center"
          style={{ textDecoration: "none" }}
        >
          {t("403.btn")}
        </Link>
      </Button>
    </Box>
  );
};

export default ForbiddenPage;
