import {
  Box,
  SimpleGrid,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { getRequirementsList } from "../../../services/requirementService";
import { Requirement } from "../../../models/requirement";
import { requirementsSearchValidationSchema } from "../../../utils/validation";
import RequirementCard from "../Card";

interface Props {
  refreshKey: number;
}
const RequirementsList: React.FC<Props> = ({ refreshKey }) => {
  const { t } = useTranslation();
  const [tableList, setTableList] = useState<Requirement[]>([]);

  useEffect(() => {
    fetchRequirementsList({ title: "" });
  }, []);

  const fetchRequirementsList = (data: { title: string }) => {
    getRequirementsList(data).then((res) => {
      res.sort((a: Requirement, b: Requirement) => {
        const dateA = new Date(a.publishedDate);
        const dateB = new Date(b.publishedDate);
        return dateB.getTime() - dateA.getTime();
      });
      setTableList(res);
    });
  };

  const handleSubmit = (
    values: { title: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    fetchRequirementsList(values);
    formikHelpers.setSubmitting(false);
  };

  const refreshData = () => {
    fetchRequirementsList({ title: "" });
  };

  return (
    <Box>
      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Formik
          initialValues={{ title: "" }}
          validationSchema={requirementsSearchValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <Field name="title">
                {({ field, form }: FieldProps<{ title: string }>) => (
                  <FormControl
                    isInvalid={!!(form.errors.title && form.touched.title)}
                  >
                    <Box display="flex" alignItems="center">
                      <Input
                        {...field}
                        size="md"
                        maxW={{ base: "12rem", md: "15rem" }}
                        placeholder={t("requirements.list.placeholder")}
                        value={field.value.title}
                      />
                      <Button
                        colorScheme="teal"
                        type="submit"
                        size="sm"
                        ml="1rem"
                        isLoading={formikProps.isSubmitting}
                      >
                        {t("common.btn.search")}
                      </Button>
                    </Box>
                    <FormErrorMessage>
                      {form.errors.title as string}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </Box>

      <Box mb="1rem" key={refreshKey}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {tableList.map((item, index) => (
            <RequirementCard
              key={index}
              requirementInfo={item}
              isEdit={false}
              onRefresh={refreshData}
            ></RequirementCard>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default RequirementsList;
