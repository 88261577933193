import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import RequirementDetails from "../Details";
import EditRequirement from "../Edit";
import ApplicationList from "../ApplicationList";
import { Requirement } from "../../../models/requirement";
import { useTranslation } from "react-i18next";
import EditApply from "../Apply/Edit";
import { canEdit } from "../../../utils/auth";
import { getCurrentOrganizationId } from "../../../utils/common";
import DeleteRequirement from "../Delete";
import { updateRequirementStatus } from "../../../services/requirementService";

interface CardProps {
  requirementInfo: Requirement;
  isEdit: boolean;
  onRefresh: () => void;
}

const RequirementCard: React.FC<CardProps> = ({
  isEdit,
  requirementInfo,
  onRefresh,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isApplicationOpen,
    onOpen: onApplicationOpen,
    onClose: onApplicationClose,
  } = useDisclosure();
  const {
    isOpen: isApplyOpen,
    onOpen: onApplyOpen,
    onClose: onApplyClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const handleSubmit = () => {
    onRefresh();
  };

  const requirementStatus = (status: number) => {
    return t("requirements.list.status-" + status);
  };

  const initEditApplyValues = {
    requirementId: requirementInfo.id,
    quotation: 0,
    estimatedTime: 1,
    taskDescription: "",
  };

  const updateStatus = () => {
    let status = 0;
    if (requirementInfo.status === 0) status = 4;
    if (requirementInfo.status === 1) status = 3;
    updateRequirementStatus(requirementInfo.id, status)
      .then((res) => {
        onRefresh();
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch(() => {
        toast({
          title: t("common.btn.update-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const canEditRequirement = () => {
    return requirementInfo.status === 0;
  };

  return (
    <Box
      bg={requirementInfo.isDraft ? "#eee" : "#fff"}
      borderRadius="5px"
      shadow="0px 12px 22px 0px hsla(0, 0%, 0%, .12)"
      transition="transform 0.3s"
      _hover={{
        boxShadow: "lg",
        rounded: "md",
        cursor: "pointer",
        transform: "translateY(-6px)",
      }}
    >
      <Box p="0.8rem" pt="1.3rem" position="relative">
        {!!isEdit && canEdit() && canEditRequirement() && (
          <Box
            position="absolute"
            top="2px"
            right="0.8rem"
            _hover={{ color: "teal" }}
          >
            <FiEdit cursor="pointer" onClick={onCreateOpen} />
          </Box>
        )}
        <Box onClick={onOpen}>
          <Center>
            <Heading
              w="100%"
              textAlign="left"
              fontSize={"large"}
              mb="0.5rem"
              h="2.4em"
              noOfLines={2}
            >
              {requirementInfo.title}
            </Heading>
          </Center>
          <Flex mb={2} alignItems="center">
            <Text fontWeight="700" size="sm" color="gray.400">
              {t("requirements.list.status")}:
            </Text>
            <Text size="sm" pl="1rem">
              {requirementStatus(requirementInfo.status)}
            </Text>
          </Flex>
          <Flex mb={2} alignItems="center">
            <Text fontWeight="700" size="sm" color="gray.400">
              {t("requirements.list.estimate")}:
            </Text>
            <Text size="sm" pl="1rem">
              {requirementInfo.estimatedTime}
            </Text>
          </Flex>
          <Text fontWeight="700" size="sm" mb={2} color="gray.400">
            {t("requirements.create.desc")}:
          </Text>
          <Text
            h="6em"
            px="1rem"
            py="0rem"
            mb="0.5rem"
            noOfLines={4}
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: requirementInfo.description,
            }}
          />
          <Flex justifyContent="flex-end">
            <Text>{requirementInfo.publisherOrganizationName}</Text>
            <Text px="0.5rem">|</Text>
            <Text>{requirementInfo?.publishedDate.substring(0, 10)}</Text>
          </Flex>
        </Box>

        {!!isEdit && (
          <Flex justifyContent="flex-end">
            <Text
              as="u"
              cursor="pointer"
              color="purple"
              onClick={onApplicationOpen}
            >
              {t("requirements.apply.application-title")}(
              {requirementInfo.applicationsCount})
            </Text>
          </Flex>
        )}
        {!!isEdit && canEdit() && requirementInfo.isDraft && (
          <Button
            mt="0.5rem"
            size="sm"
            width="full"
            colorScheme="red"
            onClick={onDeleteOpen}
          >
            {t("common.btn.delete")}
          </Button>
        )}

        {!!isEdit &&
          canEdit() &&
          !requirementInfo.isDraft &&
          requirementInfo.status === 0 && (
            <Button
              mt="0.5rem"
              size="sm"
              width="full"
              colorScheme="yellow"
              onClick={updateStatus}
            >
              {t("common.btn.close")}
            </Button>
          )}
        {!!isEdit &&
          canEdit() &&
          !requirementInfo.isDraft &&
          requirementInfo.status === 1 && (
            <Button
              mt="0.5rem"
              size="sm"
              width="full"
              colorScheme="yellow"
              onClick={updateStatus}
            >
              {t("common.btn.complete")}
            </Button>
          )}
      </Box>

      {!isEdit && requirementInfo.status === 0 && canEdit() && (
        <Box p="0.8rem" bgColor="#fff">
          {requirementInfo?.publisherOrganizationId !==
            getCurrentOrganizationId() && (
            <>
              {requirementInfo?.isApplicant ? (
                <Button size="sm" w="100%" colorScheme="gray">
                  {t("requirements.apply.applied")}
                </Button>
              ) : (
                <Button
                  size="sm"
                  w="100%"
                  colorScheme="teal"
                  onClick={onApplyOpen}
                >
                  {t("requirements.apply.title")}
                </Button>
              )}
            </>
          )}
        </Box>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="sm"
      >
        <DrawerContent>
          <DrawerCloseButton />
          <RequirementDetails
            requirementInfo={requirementInfo}
          ></RequirementDetails>
        </DrawerContent>
      </Drawer>

      {isCreateOpen && (
        <EditRequirement
          isOpen={isCreateOpen}
          onOpen={onCreateOpen}
          onClose={onCreateClose}
          onRefresh={handleSubmit}
          initialValues={requirementInfo}
        ></EditRequirement>
      )}
      {isApplicationOpen && (
        <ApplicationList
          isOpen={isApplicationOpen}
          onOpen={onApplicationOpen}
          onClose={onApplicationClose}
          onRefresh={handleSubmit}
          requirementId={requirementInfo.id}
        ></ApplicationList>
      )}

      {isApplyOpen && (
        <EditApply
          isOpen={isApplyOpen}
          onOpen={onApplyOpen}
          onClose={onApplyClose}
          initialValues={initEditApplyValues}
          onRefresh={handleSubmit}
        ></EditApply>
      )}

      {isDeleteOpen && (
        <DeleteRequirement
          isOpen={isDeleteOpen}
          onOpen={onDeleteOpen}
          onClose={onDeleteClose}
          onRefresh={handleSubmit}
          requirementId={requirementInfo.id}
        ></DeleteRequirement>
      )}
    </Box>
  );
};

export default RequirementCard;
