import axios from "axios";
import { authPaths } from "../constants/constants";

const baseURL =
  process.env.REACT_APP_ENV === "development"
    ? "/api"
    : process.env.REACT_APP_API;

const instance = axios.create({
  baseURL: baseURL,
});

// request
instance.interceptors.request.use(
  (config) => {
    const { url } = config;
    if (authPaths.some((path) => url!.startsWith(path))) {
      return config;
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        localStorage.clear();
        window.location.href = "/";
      }
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

// response
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.error("Request failed with status 401: Unauthorized");
      localStorage.clear();
      if (!authPaths.some((path) => error.config.url!.startsWith(path))) {
        window.location.href = "/";
      }
    } else {
      console.error("Request failed:", error);
    }
    return Promise.reject(error);
  }
);

export default instance;
