import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import OrganizationList from "./List";

const OrganizationManagement: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();

  return (
    <Box maxW="1240px" margin="0 auto">
      <Box bg={"#fff"} p="1rem" mb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.organization-management")}
        </Text>
      </Box>
      <OrganizationList></OrganizationList>
    </Box>
  );
};

export default OrganizationManagement;
