import { uploadSingleFile } from "./commonService";

class MyUploadAdapter {
  private loader: any;
  private onComplete: (url: string) => void;

  constructor(loader: any, onComplete: (url: string) => void) {
    this.loader = loader;
    this.onComplete = onComplete;
  }

  upload() {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      this.loader.file.then((file: File) => {
        data.append("file", file);
        data.append("folderName", "blogs");
        uploadSingleFile(data)
          .then((result) => {
            this.onComplete(result.url);
            resolve({
              default: result.url,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  abort() {
    // 如果需要，可以实现中止上传的逻辑
  }
}

export default MyUploadAdapter;
