import { AddIcon, CloseIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Image,
  ScaleFade,
  Text,
  VStack,
  chakra,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useId } from "react";
import { CaseImage } from "../../models/case";

interface SingleUploadImageProps {
  size?: string;
  rounded?: string;
  onUpdateFile: (fileList: File[]) => void;
  onDeleteImage: (images: CaseImage[]) => void;
  initImages?: CaseImage[];
  maxUploadCount?: number;
  maxSize?: number; // 单位为字节
}

const UploadImage: React.FC<SingleUploadImageProps> = ({
  size = "100px",
  rounded = "full",
  onUpdateFile,
  onDeleteImage,
  initImages = [],
  maxUploadCount = 1,
  maxSize = 10 * 1024 * 1024, // 默认大小为10MB
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [initialImages, setInitialImages] = useState<CaseImage[]>(initImages);
  const [deletedImages, setDeletedImages] = useState<CaseImage[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<File | string | null>(
    null
  );
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const uniqueId = useId();
  const toast = useToast();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxSize) {
        toast({
          title: "File is too large.",
          description: `The file size should not exceed ${
            maxSize / (1024 * 1024)
          }MB.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      const newFiles = [...uploadedFiles, file];
      setUploadedFiles(newFiles);
      onUpdateFile(newFiles);
    }
  };

  const handleDelete = (index: number, isInitialImage: boolean) => {
    if (isInitialImage) {
      const image = initialImages[index];
      const newImages = initialImages.filter((_, i) => i !== index);
      setInitialImages(newImages);
      const _deletedImages = [...deletedImages, image];
      setDeletedImages(_deletedImages);
      onDeleteImage(_deletedImages);
    } else {
      const newFiles = uploadedFiles.filter((_, i) => i !== index);
      setUploadedFiles(newFiles);
      onUpdateFile(newFiles);
    }
  };

  const handleView = (file: File | string) => {
    setSelectedImage(file);
    onOpen();
  };

  const alwaysShowButtons = useBreakpointValue({ base: true, md: false });

  const canUploadMore =
    initialImages.length - deletedImages.length + uploadedFiles.length <
    maxUploadCount;

  return (
    <>
      {canUploadMore && (
        <Center
          w={size}
          h={size}
          as={chakra.label}
          htmlFor={`file-${uniqueId}`}
          bg="whiteAlpha.500"
          border="1px dashed gray"
          rounded={rounded}
          cursor="pointer"
          overflow="hidden"
          position="relative"
          mb={4}
        >
          <Center
            position="absolute"
            w="100%"
            h="100%"
            _hover={{ bg: "blackAlpha.600" }}
          >
            <VStack>
              <AddIcon />
              <Text fontSize="12px">Upload</Text>
            </VStack>
          </Center>
          <chakra.input
            style={{ display: "none" }}
            type="file"
            id={`file-${uniqueId}`}
            onChange={handleFileChange}
          />
        </Center>
      )}
      <Box display="flex" flexWrap="wrap">
        {initialImages.map((image, index) => (
          <Box
            w={size}
            h={size}
            key={`initial-${index}`}
            position="relative"
            mb={4}
            mr="0.5rem"
            onMouseEnter={() => !alwaysShowButtons && setHoveredIndex(index)}
            onMouseLeave={() => !alwaysShowButtons && setHoveredIndex(null)}
          >
            <ScaleFade initialScale={0.9} in={true}>
              <Image
                w={size}
                h={size}
                src={image.url}
                alt="Uploaded"
                rounded={rounded}
                objectFit="cover"
                cursor="pointer"
                onClick={() => handleView(image.url)}
                _hover={{ transform: "scale(1.05)", transition: "0.2s" }}
              />
            </ScaleFade>
            {(hoveredIndex === index || alwaysShowButtons) && (
              <>
                <Button
                  size="xs"
                  position="absolute"
                  top="5px"
                  right="5px"
                  onClick={() => handleDelete(index, true)}
                >
                  <CloseIcon />
                </Button>
                <Button
                  size="xs"
                  position="absolute"
                  top="5px"
                  right="35px"
                  onClick={() => handleView(image.url)}
                >
                  <ViewIcon />
                </Button>
              </>
            )}
          </Box>
        ))}

        {uploadedFiles.map((file, index) => (
          <Box
            w={size}
            h={size}
            key={`uploaded-${index}`}
            position="relative"
            mb={4}
            mr="0.5rem"
            onMouseEnter={() =>
              !alwaysShowButtons &&
              setHoveredIndex(index + initialImages.length)
            }
            onMouseLeave={() => !alwaysShowButtons && setHoveredIndex(null)}
          >
            <ScaleFade initialScale={0.9} in={true}>
              <Image
                w={size}
                h={size}
                src={URL.createObjectURL(file)}
                alt="Uploaded"
                rounded={rounded}
                objectFit="cover"
                cursor="pointer"
                onClick={() => handleView(file)}
                _hover={{ transform: "scale(1.05)", transition: "0.2s" }}
              />
            </ScaleFade>
            {(hoveredIndex === index + initialImages.length ||
              alwaysShowButtons) && (
              <>
                <Button
                  size="xs"
                  position="absolute"
                  top="5px"
                  right="5px"
                  onClick={() => handleDelete(index, false)}
                >
                  <CloseIcon />
                </Button>
                <Button
                  size="xs"
                  position="absolute"
                  top="5px"
                  right="35px"
                  onClick={() => handleView(file)}
                >
                  <ViewIcon />
                </Button>
              </>
            )}
          </Box>
        ))}
      </Box>

      {selectedImage && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p="3rem">
              {typeof selectedImage === "string" ? (
                <Image src={selectedImage} alt="Selected" />
              ) : (
                <Image
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default UploadImage;
