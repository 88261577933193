import instance from "./interceptor";
import { Status, StopTaskRequest } from "../models/digitalWorkforce";

export const getDigitalWorkforceList = async () => {
  try {
    const response = await instance.get(`/api/DigitalWorkforce`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDigitalWorkforceById = async (id: string) => {
  try {
    const response = await instance.get(`/api/DigitalWorkforce/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteDigitalWorkforceById = async (id: string) => {
  try {
    const response = await instance.delete(`/api/DigitalWorkforce/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const upsertDigitalWorkforce = async (data: any) => {
  try {
    const response = await instance.post(`/api/DigitalWorkforce/upsert`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const upsertDigitalWorkforceStatus = async (data: any) => {
  try {
    const response = await instance.post(`/api/DigitalWorkforce/Status`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const subscribeDigitalWorkforce = async (data: any) => {
  try {
    const response = await instance.post(
      `/api/DigitalWorkforce/subscribe`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const cancelSubscribeDigitalWorkforce = async (
  subscribedDigitalWorkforceId: string,
  status: number
) => {
  try {
    const response = await instance.put(
      `/api/SubscribedDigitalWorkforce/${subscribedDigitalWorkforceId}/${status}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDigitalWorkforceListByOrganization = async () => {
  try {
    const response = await instance.get(
      `/api/DigitalWorkforce/SubscribedOrganizations`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getSubscribedDigitalWorkforceById = async (id: string) => {
  try {
    const response = await instance.get(`/api/Rpa/Subscribed/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getStatusText = (status: Status): string => {
  switch (status) {
    case Status.Offline:
      return "Offline";
    case Status.Idle:
      return "Idle";
    case Status.Working:
      return "Working";
    default:
      return "Not Available";
  }
};

export const getStatusIcon = (status: Status): JSX.Element => {
  switch (status) {
    case Status.Offline:
      return <span style={{ color: "gray" }}>●</span>; // 灰色圆点
    case Status.Idle:
      return <span style={{ color: "green" }}>●</span>; // 绿色圆点
    case Status.Working:
      return <span className="loading-icon">⏳</span>; // 转圈loading图标，这里用⏳代替，实际可以使用图标库
    default:
      return <span />;
  }
};

export const enableSubscribedFunction = async (id: string) => {
  try {
    const response = await instance.put(`/api/Rpa/EnableTaskGroup/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const disableSubscribedFunction = async (id: string) => {
  try {
    const response = await instance.put(`/api/Rpa/DisableTaskGroup/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const executeSubscribedFunction = async (id: string) => {
  try {
    const response = await instance.post(`/api/Rpa/ExecuteSingleTask/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const stopExecutedTask = async (data: StopTaskRequest) => {
  try {
    const response = await instance.post(`/api/Rpa/StopGroupTask`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
