import { useEffect, useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Input,
  FormControl,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { isPlatformAdmin } from "../../../../utils/auth";
import {
  ReleaseDIgitalWorkforceFunction,
  ReleaseDIgitalWorkforceTask,
} from "../../../../models/releaseDigitalWorkforce";
import EditReleaseDigitalWorkforceFunction from "./Edit";
import {
  deleteReleaseDigitalWorkforceFunctionById,
  getReleaseDigitalWorkforceFunctionList,
} from "../../../../services/releaseDigitalWorkforce";
import { releaseDigitalWorkforceFunctionSearchValidationSchema } from "../../../../utils/validation";

const ReleaseDigitalWorkforceFunction = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const toast = useToast();
  const _initValue = {
    groupName: "",
    groupDescription: "",
    currency: "USD"
  };
  const [tableList, setTableList] = useState<ReleaseDIgitalWorkforceFunction[]>(
    []
  );
  const [deleteId, setDeleteId] = useState<string>();
  const [initValue, setInitValue] =
    useState<ReleaseDIgitalWorkforceFunction>(_initValue);

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.release-digital-workforce",
      path: "/workbench/release-digital-workforce",
    },
    {
      label: "release-digital-workforce.items.function",
      path: "/workbench/release-digital-workforce/function",
    },
  ];
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const tableFiled = [
    t("release-digital-workforce.function.name"),
    t("release-digital-workforce.function.desc"),
    t("release-digital-workforce.function.task"),
    t("common.btn.actions"),
  ];

  useEffect(() => {
    fetchList({ groupName: "" });
  }, []);

  const fetchList = (data: { groupName: string }) => {
    getReleaseDigitalWorkforceFunctionList(data).then((res) => {
      setTableList(res);
    });
  };

  const handleDelete = () => {
    deleteReleaseDigitalWorkforceFunctionById(deleteId!)
      .then((res) => {
        fetchList({ groupName: "" });
        onDeleteModalClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleSubmit = (
    values: { groupName: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    fetchList(values);
    formikHelpers.setSubmitting(false);
  };

  const onRefresh = () => {
    fetchList({ groupName: "" });
  };

  const _renderFunction = (
    functions: ReleaseDIgitalWorkforceTask[] | undefined
  ) => {
    if (!functions) return "";
    const res = functions.map((item) => item.functionName);
    return res.join(", ");
  };

  const _renderCell = (
    row: ReleaseDIgitalWorkforceFunction,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
        // onClick={() => navigate(`/articles/blogs/details/${row.id}`)}
      >
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (
    row: ReleaseDIgitalWorkforceFunction,
    index: number
  ) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.groupName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.groupDescription, "sm", "normal")}
        {_renderCell(row, _renderFunction(row.functions), "sm", "normal")}
        <Td pl="0.2rem">
          <Flex>
            <Button
              size="sm"
              colorScheme="teal"
              mr="0.5rem"
              onClick={() => {
                setInitValue(row);
                onCreateModalOpen();
              }}
            >
              {t("common.btn.edit")}
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={() => {
                setDeleteId(row.id);
                onDeleteModalOpen();
              }}
            >
              {t("common.btn.delete")}
            </Button>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>

      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Button
          leftIcon={<FiPlus />}
          colorScheme="teal"
          variant="solid"
          isDisabled={!isPlatformAdmin()}
          size="md"
          onClick={() => {
            setInitValue(_initValue);
            onCreateModalOpen();
          }}
        >
          {t("release-digital-workforce.function.create")}
        </Button>
      </Box>
      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Formik
          initialValues={{ groupName: "" }}
          validationSchema={
            releaseDigitalWorkforceFunctionSearchValidationSchema
          }
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <Field name="groupName">
                {({ field, form }: FieldProps<{ groupName: string }>) => (
                  <FormControl
                    isInvalid={
                      !!(form.errors.groupName && form.touched.groupName)
                    }
                  >
                    <Box display="flex" alignItems="center">
                      <Input
                        {...field}
                        size="md"
                        maxW={{ base: "12rem", md: "15rem" }}
                        placeholder={t(
                          "release-digital-workforce.function.placeholder"
                        )}
                        value={field.value.groupName}
                      />
                      <Button
                        colorScheme="teal"
                        type="submit"
                        size="sm"
                        ml="1rem"
                        isLoading={formikProps.isSubmitting}
                      >
                        {t("common.btn.search")}
                      </Button>
                    </Box>
                    <FormErrorMessage>
                      {form.errors.title as string}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </Box>
      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: ReleaseDIgitalWorkforceFunction, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
      <EditReleaseDigitalWorkforceFunction
        isOpen={isCreateModalOpen}
        onOpen={onCreateModalOpen}
        onClose={onCreateModalClose}
        initialValues={initValue}
        onSubmit={onRefresh}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("blogs.delete-modal.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("blogs.delete-modal.content")}</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onDeleteModalClose} size="sm">
              {t("common.btn.cancel")}
            </Button>
            <Button colorScheme="red" size="sm" onClick={() => handleDelete()}>
              {t("common.btn.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ReleaseDigitalWorkforceFunction;
