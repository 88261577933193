import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

interface MarqueeProps {
  msgs: string[];
}

const Marquee: React.FC<MarqueeProps> = ({ msgs }) => {
  const animationBase = keyframes`
    0% { transform: translateY(0%); }
    100% { transform: translateY(-100%); }
  `;

  return (
    <Box
      overflow="hidden"
      width="100%"
      height="80px"
      p={2}
      borderRadius={"5px"}
      position="relative"
    >
      <Box
        as="div"
        display="flex"
        flexDirection="column"
        animation={`${animationBase} 30s linear infinite`}
        position="absolute"
        top="0"
      >
        {msgs.concat(msgs).map((msg, index) => (
          <Text
            as="span"
            fontSize={{ base: "sm", md: "2xl" }}
            mx={2}
            display="block"
            key={index}
            __css={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              wordWrap: "break-word",
            }}
          >
            {msg}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default Marquee;
