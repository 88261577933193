import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { taskTimelineValidationSchema } from "../../../../utils/validation";
import { TaskTimeline } from "../../../../models/requirement";
import { createRequirementTimeLine } from "../../../../services/requirementService";

interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: TaskTimeline;
  onRefresh: () => void;
}

const EditTaskTimeline: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleSubmit = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    createRequirementTimeLine(values)
      .then((res) => {
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
        onClose();
        onRefresh();
      })
      .catch((err) => {
        toast({
          title: t("common.btn." + opt + "-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  const renderNumberInputField = (
    name: string,
    label: string,
    form: any,
    field: any,
    props: any
  ) => (
    <FormControl isInvalid={form.errors[name] && form.touched[name]} isRequired>
      <FormLabel>{label}</FormLabel>
      <NumberInput
        {...field}
        {...props}
        name={name}
        min={1}
        max={3000}
        onChange={(val: number) => form.setFieldValue(field.name, val)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
    </FormControl>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>{t("requirements.taskTimeline.title")}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={taskTimelineValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <ModalBody>
                {[
                  "estimate",
                  "design",
                  "development",
                  "codeReview",
                  "integrationTest",
                  "inspect",
                  "settlement",
                ].map((name) => (
                  <Box my="0.5rem" key={name}>
                    <Field name={name}>
                      {({ field, form, ...props }: any) =>
                        renderNumberInputField(
                          name,
                          t(`requirements.taskTimeline.${name}`),
                          form,
                          field,
                          props
                        )
                      }
                    </Field>
                  </Box>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditTaskTimeline;
