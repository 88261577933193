import {
  Box,
  Button,
  Checkbox,
  SimpleGrid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import RequirementCard from "../Card";
import EditRequirement from "../Edit";
import { Requirement, RequirementForm } from "../../../models/requirement";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { getRequirementsList } from "../../../services/requirementService";
import { useEffect, useState } from "react";
import { canEdit } from "../../../utils/auth";

interface Props {
  onRefresh: () => void;
}

const PublishList: React.FC<Props> = ({ onRefresh }) => {
  const [tableList, setTableList] = useState<Requirement[]>([]);
  const [filteredData, setFilteredData] = useState<Requirement[]>([]);
  const [filters, setFilters] = useState({
    all: true,
    unclaimed: false,
    claimed: false,
    inProgress: false,
    completed: false,
    closed: false,
  });
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const { t } = useTranslation();
  const initValue = {
    title: "",
    status: 0,
    estimatedTime: 1,
  } as RequirementForm;

  useEffect(() => {
    fetchRequirementsList();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [tableList, filters]);

  const fetchRequirementsList = () => {
    getRequirementsList({ publishedByCurrentUser: true }).then((res) => {
      setTableList(res);
    });
  };

  const refreshData = () => {
    fetchRequirementsList();
    onRefresh();
  };

  const handleFilterChange = (filterKey: keyof typeof filters) => {
    const newFilters = { ...filters, [filterKey]: !filters[filterKey] };

    if (filterKey === "all") {
      setFilters({
        all: true,
        unclaimed: false,
        claimed: false,
        inProgress: false,
        completed: false,
        closed: false,
      });
    } else {
      newFilters.all = false;
      setFilters(newFilters);
    }
  };

  const applyFilters = () => {
    if (filters.all) {
      setFilteredData(tableList);
    } else {
      const filteredList = tableList.filter((item) => {
        if (filters.unclaimed && item.status === 0) return true;
        if (filters.claimed && item.status === 1) return true;
        if (filters.inProgress && item.status === 2) return true;
        if (filters.completed && item.status === 3) return true;
        if (filters.closed && item.status === 4) return true;
        return false;
      });
      setFilteredData(filteredList);
    }
  };

  // FilterCheckbox 组件
  const FilterCheckbox = ({
    label,
    filterKey,
  }: {
    label: string;
    filterKey: keyof typeof filters;
  }) => (
    <Checkbox
      size="md"
      colorScheme="green"
      isChecked={filters[filterKey]}
      onChange={() => handleFilterChange(filterKey)}
    >
      {label}
    </Checkbox>
  );

  return (
    <Box>
      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Button
          leftIcon={<FiPlus />}
          colorScheme="teal"
          variant="solid"
          isDisabled={!canEdit()}
          size="md"
          onClick={onCreateOpen}
        >
          {t("requirements.create.btn")}
        </Button>
      </Box>
      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Stack spacing={[1, 5]} direction={["column", "row"]}>
          <FilterCheckbox
            label={t("requirements.list.status-all")}
            filterKey="all"
          />
          <FilterCheckbox
            label={t("requirements.list.status-0")}
            filterKey="unclaimed"
          />
          <FilterCheckbox
            label={t("requirements.list.status-1")}
            filterKey="claimed"
          />
          <FilterCheckbox
            label={t("requirements.list.status-2")}
            filterKey="inProgress"
          />
          <FilterCheckbox
            label={t("requirements.list.status-3")}
            filterKey="completed"
          />
          <FilterCheckbox
            label={t("requirements.list.status-4")}
            filterKey="closed"
          />
        </Stack>
      </Box>
      <Box mb="1rem">
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {filteredData.map((item, index) => (
            <RequirementCard
              key={index}
              requirementInfo={item}
              isEdit={true}
              onRefresh={refreshData}
            ></RequirementCard>
          ))}
        </SimpleGrid>
      </Box>
      <EditRequirement
        isOpen={isCreateOpen}
        onOpen={onCreateOpen}
        onClose={onCreateClose}
        onRefresh={refreshData}
        initialValues={initValue}
      ></EditRequirement>
    </Box>
  );
};

export default PublishList;
