import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Card,
  Input,
  CardHeader,
  HStack,
  CardBody,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DigitalWorkforceRow } from "../../models/digitalWorkforce";
import TagSelector from "../../components/TagSelector";
import {
  getDigitalWorkforceList,
} from "../../services/digitalWorkforceService";
import { domainList } from "../../constants/constants";
import { isPlatformAdmin } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import {
  _renderDigitalWorkforceStatus,
  _renderDigitalWorkforceType,
} from "../../utils/digitalWorkforce";
import { isEmpty } from "lodash";
interface DigitalWorkforceProps {
  isFromWorkbench?: boolean;
}
const DigitalWorkforce: React.FC<DigitalWorkforceProps> = ({
  isFromWorkbench = false,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.700", "white");
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.digital-workforce", path: "/digital-workforce" },
  ];

  const [digitalWorkforceList, setDigitalWorkforceList] = useState<
    DigitalWorkforceRow[]
  >([]);
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [filteredList, setFilteredList] = useState<DigitalWorkforceRow[]>([]);

  useEffect(() => {
    filterList();
  }, [selectedDomains, digitalWorkforceList]);

  useEffect(() => {
    fetchDigitalWorkforceList();
  }, []);

  const filterList = () => {
    if (selectedDomains.length === 0) {
      setFilteredList(digitalWorkforceList);
      return;
    }
    const filteredByDomains =
      selectedDomains.length === 0
        ? digitalWorkforceList
        : digitalWorkforceList.filter(
            (item) =>
              item.domains &&
              item.domains.some((domain) => selectedDomains.includes(domain))
          );

    const filtered = filteredByDomains;
    setFilteredList(filtered);
  };

  const fetchDigitalWorkforceList = () => {
    getDigitalWorkforceList().then((res) => {
      let digitalWorkforceList = res;
      if (isFromWorkbench && !isPlatformAdmin()) {
        digitalWorkforceList = digitalWorkforceList.filter(
          (item: DigitalWorkforceRow) => item.subscribeStatus === true
        );
      }
      setDigitalWorkforceList(digitalWorkforceList);
    });
  };

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box p="1rem" bg="white" mb="1rem">
        <Text
          fontSize="xl"
          pb={isPlatformAdmin() ? "1rem" : 0}
          color={textColor}
          fontWeight="bold"
        >
          {t("digital-workforce.list.header")}
        </Text>
      </Box>
      <Box p="1rem" mb="1rem" bgColor="white" alignItems="center">
      <Input
          size="md"
          maxW={{ base: "12rem", md: "15rem" }}
          placeholder={t(
            "digital-workforce.list.placeholder"
          )}
        /><Button
        colorScheme="teal"
        type="submit"
        size="sm"
        ml="1rem"
      >
        {t("common.btn.search")}
        </Button>
      </Box>

      <Box p="1rem" mb="1rem" bgColor="white">
        <Box>
          <TagSelector
            name={t("digital-workforce.list.domains")}
            items={domainList}
            selectedItems={selectedDomains}
            setSelectedItems={setSelectedDomains}
          />
        </Box>
      </Box>

      <Box my="1rem">
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {filteredList.map((row, index) => {
            return (
              <Card maxW='md'>
                <CardHeader>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                <Avatar
                  name={row.name}
                  bg="teal.500"
                  color="white"
                />
                <Box flexBasis={'140px'}>
                  <Heading size='sm'>{row.name}</Heading>
                </Box>
              </Flex> 
              <Flex position={'absolute'} top={'10px'} right={'10px'} fontSize="0.8rem">
                    <Text pr={2} pt={1} className={`type-tag type-${row.type}`}>
                    {t(_renderDigitalWorkforceType(row.type))}
                    </Text>
                </Flex>    
      </CardHeader>
      <CardBody>
        <HStack justify="center">
          <Flex flexDirection="column" alignItems="center" w="50%">
            <Text fontSize="2xl" fontWeight="bold" color="blue.500">
              {row.executionCount}
            </Text>
            <Text fontSize="12px" color="gray">
              {t("digital-workforce.list.number-of-executions")}
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" w="50%">
            <Text fontSize="2xl" fontWeight="bold" color="blue.500">
              {row.totalExecutionTime}h
            </Text>
            <Text fontSize="12px" color="gray">
              {t("digital-workforce.list.total-execution-time")}
            </Text>
          </Flex>
        </HStack>

                <Text mt={2} textAlign="center">
                  {t("digital-workforce.list.subscribed-org")}
                </Text>

                <Stack direction="row" spacing={-10} justify="center" mt={1}>
                  {isEmpty(row?.subscribedOrganizations) ? (
                    <Avatar size="md" />
                  ) : (
                    <>
                      {row?.subscribedOrganizations
                        ?.slice(0, 5)
                        .map((organization) => {
                          return <Avatar size="md" name={organization} />;
                        })}
                    </>
                  )}
                </Stack>
                <Box py={4}>
                  <Button
                    width={"full"}
                    variant="outline"
                    colorScheme="teal"
                    onClick={() => {
                      navigate(`/digital-workforce/details/${row.id}`)
                    }}
                  >
                    {t("digital-workforce.list.view-details")}
                  </Button>
                </Box>
            </CardBody>

            </Card>)
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default DigitalWorkforce;
