import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface CountdownTimerProps {
  initialCount: number;
  redirectUrl: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialCount,
  redirectUrl,
}) => {
  const [count, setCount] = useState(initialCount);
  const navigate = useNavigate();

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount((prevCount: number) => prevCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      navigate(redirectUrl);
    }
  }, [count]);

  const formatTime = () => {
    return count < 10 ? `0${count}` : count.toString();
  };

  return (
    <Box as="span" fontWeight="bold" fontSize="1.3rem" px="0.5rem">
      {formatTime()}
    </Box>
  );
};

export default CountdownTimer;
