import { Box, Grid, GridItem } from "@chakra-ui/react";
import Organization from "./Organization";

const Settings = () => {
  return (
    <Box maxW="1240px" margin="0 auto">
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={6}
      >
        <GridItem w="100%">
          <Organization></Organization>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Settings;
