import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BreadcrumbProps {
  routes: { label: string; path: string }[];
}

const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({ routes }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumb separator="/">
      {routes.map((route, index) => {
        const isLast = index === routes.length - 1;
        return (
          <BreadcrumbItem key={route.path} isCurrentPage={isLast}>
            {isLast ? (
              <BreadcrumbLink>{t(route.label)}</BreadcrumbLink>
            ) : (
              <BreadcrumbLink
                _hover={{ color: "teal" }}
                as={Link}
                to={route.path}
              >
                {t(route.label)}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
