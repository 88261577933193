import { Box, Center, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { Blog, EditBlogForm } from "../../../models/blog";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
interface BlogsProps {
  isOpen?: boolean;
  onClose?: () => void;
  initValues: Blog;
  isPreview: boolean;
}
const BlogCard: React.FC<BlogsProps> = ({
  isOpen,
  onClose,
  initValues,
  isPreview,
}) => {
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.blogs", path: "/articles/blogs" },
  ];
  if (!isPreview) {
    breadcrumbRoutes.push({ label: initValues.title, path: initValues.id! });
  }
  return (
    <Box maxW="1240px" margin="0 auto">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box
        bg={"#fff"}
        p={{ base: "1rem", md: "1.5rem" }}
        minH="calc(100vh - 80px)"
      >
        {isPreview && (
          <Flex>
            <Button onClick={onClose}>{t("common.btn.close-preview")}</Button>
          </Flex>
        )}

        <Heading mb="2rem" textAlign="center">
          {initValues?.title}
        </Heading>
        {!isPreview && (
          <Flex pb="1rem" justifyContent="flex-end">
            <Flex>
              <Text color="teal" cursor="pointer">
                {initValues?.createrOrganizationName}
              </Text>
              <Text px="0.5rem">|</Text>
              <Text color="gray">
                {initValues?.createdDate?.replace("T", " ")}
              </Text>
            </Flex>
          </Flex>
        )}

        <Text
          py="0rem"
          mb="0.5rem"
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: initValues?.content!,
          }}
        />
      </Box>
    </Box>
  );
};

export default BlogCard;
