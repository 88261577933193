import { Box, Text, Image } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
import Img from "../../../../../assets/img/carousel/example3.jpg";

const Step3 = () => {
  const { t } = useTranslation();
  const scrollAnimation = keyframes`
  0% { transform: translateY(0%); }
  100% { transform: translateY(-50%); }
`;
  return (
    <Box minH="500px" w="360px" border="1px solid rgb(226, 232, 240)" p="2rem">
      <Text mb="1rem">{t("case.guide.step3.page")}</Text>
      <Text>{t("case.guide.step3.desc")}</Text>
      <Box boxShadow="lg" minH="300px" px="0.5rem" py="1rem">
        <Box width="100%" height="300px" overflow="hidden" position="relative">
          <Box
            as={Image}
            src={Img}
            animation={`${scrollAnimation} 8s linear infinite`}
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="800px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;
