
import instance from "./interceptor";

export const upsertOrganizationInfo = async (data: any) => {
  try {
    const response = await instance.post("/api/organization/upsert", data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
