import React, { useState, useEffect } from "react";
import {
  getAllTasks,
  executeTask,
  addOrEditTask,
} from "../../services/taskService";
import { Task } from "../../models/task";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  GridItem,
  Input,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";

const TaskManagement = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [payload, setPayload] = useState("");
  const [vhost, setVhost] = useState("/linkedin");
  const [queueName, setQueueName] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [operateTask, setOperateTask] = useState<Task>({
    id: "",
    taskName: "",
    taskPayload: "",
  });

  useEffect(() => {
    getAllTasks().then((tasks) => {
      setTasks(tasks);
      if (tasks.length > 0) {
        setSelectedTask(tasks[0]);
        setPayload(tasks[0].taskPayload);
      }
    });
  }, []);

  const handleTaskChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const task = tasks.find(
      (task: Task) => task.taskName === event.target.value
    );
    if (task) {
      setSelectedTask(task);
      setPayload(task.taskPayload);
    }
  };

  const handleExecuteTask = async () => {
    if (!payload || !vhost || !queueName) {
      alert("Payload, Vhost and QueueName fields cannot be empty");
      return;
    }
    let res = await executeTask(vhost, queueName, payload);
    if (res === 200) {
      alert("Task executed successfully");
    } else {
      alert("Task execution failed");
    }
  };

  const handleEditStatus = () => {
    setEditStatus(true);

    setOperateTask((prevTask) => {
      const updatedTask: Task = Object.assign({}, prevTask, {
        id: selectedTask?.id,
        taskName: selectedTask?.taskName,
        taskPayload: selectedTask?.taskPayload,
      });

      return updatedTask;
    });
  };

  const handleAddStatus = () => {
    setEditStatus(true);
  };

  const handleOperateTask = async () => {
    if (!operateTask?.taskName || !operateTask?.taskPayload) {
      alert("Task name and Task Payload fields cannot be empty");
      return;
    }
    let res = await addOrEditTask(operateTask);
    if (res === 200) {
      alert("Task operate successfully");
      window.location.reload(); // 刷新页面
    } else {
      alert("Task operate failed");
    }
  };

  const handleTaskNameChange = (value: string) => {
    setOperateTask((prevTask) => ({ ...prevTask, taskName: value }));
  };

  const handlePayloadChange = (value: string) => {
    editStatus
      ? setOperateTask((prevTask) => ({ ...prevTask, taskPayload: value }))
      : setPayload(value);
  };

  return (
    <Box maxW="1240px" margin="0 auto">
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={6}
      >
        <GridItem w="100%">
          <Card width="100%" p="1rem">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <ButtonGroup>
                <Button
                  colorScheme="teal"
                  size="sm"
                  variant="solid"
                  onClick={handleAddStatus}
                >
                  Add
                </Button>
                <Button
                  colorScheme="teal"
                  size="sm"
                  variant="solid"
                  onClick={handleEditStatus}
                >
                  Edit
                </Button>
              </ButtonGroup>
            </Box>
            <Box width="100%">
              <Text>Tasks:</Text>
              {editStatus ? (
                <Input
                  value={operateTask?.taskName || ""}
                  onChange={(e) => handleTaskNameChange(e.target.value)}
                />
              ) : (
                <Select onChange={handleTaskChange}>
                  {tasks.map((task) => (
                    <option key={task.id} value={task.taskName}>
                      {task.taskName}
                    </option>
                  ))}
                </Select>
              )}

              <Text style={{ marginTop: "10px" }}>Task Payload</Text>
              <Textarea
                value={editStatus ? operateTask.taskPayload : payload}
                onChange={(e) => handlePayloadChange(e.target.value)}
                isReadOnly={!editStatus}
              />
              <Text style={{ marginTop: "10px" }}>Vhost:</Text>
              <Input
                value={vhost}
                onChange={(e) => setVhost(e.target.value)}
                isReadOnly={!editStatus}
              />
              <Text style={{ marginTop: "10px" }}>Queue Name: </Text>
              <Input
                value={queueName}
                onChange={(e) => setQueueName(e.target.value)}
                isReadOnly={!editStatus}
              />

              <ButtonGroup style={{ marginTop: "20px" }}>
                <Button
                  onClick={handleExecuteTask}
                  colorScheme="teal"
                  size="sm"
                  variant="solid"
                >
                  Execute Task
                </Button>

                {editStatus && (
                  <Button
                    colorScheme="teal"
                    size="sm"
                    variant="solid"
                    onClick={handleOperateTask}
                  >
                    Save
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default TaskManagement;
