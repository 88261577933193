import React from "react";
import { MinusIcon } from "@chakra-ui/icons"; 


const RemoveButton = (props) => {
  let { removeElement, removeFrom, removeKey, hidden, styles } = props;
  return (
    <span
      hidden={hidden}
      title="remove item"
      onClick={() => removeElement(removeFrom, removeKey)}
    >
      <span style={styles.removeButton}><MinusIcon /></span>
    </span>
  );
};

export default RemoveButton;
