import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Requirement } from "../../../models/requirement";
import { useTranslation } from "react-i18next";

interface RequirementProps {
  requirementInfo: Requirement;
}

const RequirementDetails: React.FC<RequirementProps> = (props) => {
  const { requirementInfo } = props;
  const { t } = useTranslation();
  const requirementStatus = (status: number) => {
    return t("requirements.list.status-" + status);
  };
  return (
    <Box bg={"#fff"} p="1rem" pt="5rem" pb="0" overflowY={{ base: "scroll" }}>
      <Heading fontSize="larger" mb="1rem">
        {requirementInfo.title}
      </Heading>

      <Flex mb={2} alignItems="center">
        <Text fontWeight="700" size="sm" color="gray.400">
          {t("requirements.list.status")}:
        </Text>
        <Text size="sm" pl="1rem">
          {requirementStatus(requirementInfo.status)}
        </Text>
      </Flex>
      <Flex mb={2} alignItems="center">
        <Text fontWeight="700" size="sm" color="gray.400">
          {t("requirements.list.published-by")}:
        </Text>
        <Text size="sm" pl="1rem">
          {requirementInfo.publisherOrganizationName}
        </Text>
      </Flex>
      <Flex mb={2} alignItems="center">
        <Text fontWeight="700" size="sm" color="gray.400">
          {t("requirements.list.published-date")}:
        </Text>
        <Text size="sm" pl="1rem">
          {requirementInfo?.publishedDate.replace("T", " ")}
        </Text>
      </Flex>
      <Flex mb={2} alignItems="center">
        <Text fontWeight="700" size="sm" color="gray.400">
          {t("requirements.list.updated-date")}:
        </Text>
        <Text size="sm" pl="1rem">
          {requirementInfo.updatedDate
            ? requirementInfo?.updatedDate.replace("T", " ")
            : ""}
        </Text>
      </Flex>
      <Flex mb={2} alignItems="center">
        <Text fontWeight="700" size="sm" color="gray.400">
          {t("requirements.list.estimate")}:
        </Text>
        <Text size="sm" pl="1rem">
          {requirementInfo.estimatedTime}
        </Text>
      </Flex>

      <Text fontWeight="700" size="sm" mb={2} color="gray.400">
        Description:
      </Text>
      <Text
        px="1rem"
        py="0rem"
        mb="1rem"
        className="ck-content"
        dangerouslySetInnerHTML={{
          __html: requirementInfo.description,
        }}
      />
    </Box>
  );
};

export default RequirementDetails;
