export const _renderDigitalWorkforceStatus = (status: number) => {
  return status === 1 ? "common.published" : "common.draft";
};

export const _renderDigitalWorkforceType = (type: number) => {
  if(type === 0) {
    return "common.role-type.share";
  } else if (type === 1) {
    return "common.role-type.standard";
  }
  return "common.role-type.customized";
};
