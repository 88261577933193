import { BlogQuery } from "../models/blog";
import instance from "./interceptor";

export const getBlogList = async (data?: BlogQuery) => {
  try {
    const response = await instance.get(`/api/blogs`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getBlogDetail = async (blogId: string) => {
  try {
    const response = await instance.get(`/api/blogs/${blogId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateBlog = async (data: any) => {
  try {
    const response = await instance.post(`/api/blogs`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteBlog = async (id: string) => {
  try {
    const response = await instance.delete(`/api/blogs/draft/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
