import instance from "./interceptor";

export const uploadSingleFile = async (data: any) => {
  try {
    const response = await instance.post(`/api/file`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
