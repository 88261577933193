import { Box, Button, Heading, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const NotFoundPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Box
      maxW="1240px"
      margin="0 auto"
      textAlign="center"
      py={10}
      px={6}
      mt={10}
      bgColor="white"
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        {t("404.title")}
      </Text>
      <Text color={"gray.500"} mb={6}>
        {t("404.sub-title")}
      </Text>

      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
      >
        <Link
          as={RouterLink}
          to={"/"}
          display="flex"
          alignItems="center"
          style={{ textDecoration: "none" }}
        >
          {t("404.btn")}
        </Link>
      </Button>
    </Box>
  );
};

export default NotFoundPage;
