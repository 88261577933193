import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BlogsList from "./List";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const Blogs = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.blogs", path: "/articles/blogs" },
  ];
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab === "my") {
      setTabIndex(1);
    } else {
      setTabIndex(0);
    }
  }, [location.search]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    const tab = index === 1 ? "my" : "all";
    navigate(`/articles/blogs?tab=${tab}`);
  };

  return (
    <Box maxW="1240px" margin="0 auto">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" mb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.blogs")}
        </Text>
      </Box>
      <BlogsList isEdit={false}></BlogsList>
    </Box>
  );
};

export default Blogs;
