import React from "react";
import { AddIcon } from "@chakra-ui/icons"; 

const DuplicateButton = (props) => {
  let { duplicateElement, addTo, hidden, styles } = props;
  return (
    <span hidden={hidden} title="duplicate item" onClick={() => duplicateElement(addTo)}>
      <span style={styles.duplicateButton}><AddIcon /></span>
    </span>
  );
};

export default DuplicateButton;
