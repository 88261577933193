import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Blog } from "../../../models/blog";
import BlogCard from "../Card";
import { getBlogDetail } from "../../../services/blogService";

const BlogDetails = () => {
  const params = useParams();
  const id = params.id;
  const [initValues, setInitValues] = useState<Blog>();
  useEffect(() => {
    if (!!id) {
      getBlogDetail(id).then((res) => {
        setInitValues(res);
      });
    }
  }, []);

  return (
    <>
      {!!initValues && (
        <BlogCard isPreview={false} initValues={initValues}></BlogCard>
      )}
    </>
  );
};

export default BlogDetails;
