import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AuthInfo, OrganizationInfo } from "../../../models/auth";
import { organizationInfoValidationSchema } from "../../../utils/validation";
import { upsertOrganizationInfo } from "../../../services/userService";
import { userInitial } from "../../../services/authService";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../store";
import { setDefaultOrganization } from "../../../services/organizationService";
import { setLocalInfo } from "../../../utils/auth";
interface OrganizationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  organizationInfo: OrganizationInfo;
}
const OrganizationEdit: React.FC<OrganizationProps> = ({
  isOpen,
  onClose,
  onConfirm,
  organizationInfo,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleSubmit = (
    values: OrganizationInfo,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let data = {
      name: values.name,
      description:
        typeof values.description !== "undefined" ? values.description : "",
      number: typeof values.number !== "undefined" ? values.number : 0,
    } as OrganizationInfo;

    if (values.id) {
      data.id = values.id;
    }

    upsertOrganizationInfo(data)
      .then((res) => {
        onClose();
        userInitial().then((res) => {
          updateLocalInfo(res.currentOrganizationId);
          onConfirm();
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((res) => {
        formikHelpers.setSubmitting(false);
      });
  };

  const updateLocalInfo = (id: string) => {
    setDefaultOrganization(id).then((res: AuthInfo) => {
      setLocalInfo(res);
      const userInfo = res.user;
      dispatch(setUserInfo(userInfo));
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Card width="100%" p="1rem">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("organization.list.header")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={organizationInfo}
              validationSchema={organizationInfoValidationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel> {t("organization.list.name")}</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="number">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.number && form.touched.number}
                      >
                        <FormLabel>{t("organization.list.number")} :</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.number}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="description">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.description && form.touched.description
                        }
                      >
                        <FormLabel>
                          {t("organization.list.description")} :
                        </FormLabel>
                        <Textarea {...field} minH="15rem" />
                        <FormErrorMessage>
                          {form.errors.description}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    type="submit"
                    size="sm"
                  >
                    {t("common.btn.submit")}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default OrganizationEdit;
