import React, { useEffect } from "react";
import {
  Box,
  Icon,
  useColorModeValue,
  Link,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./index.css";
import routes from "../../../../config/routes";
import { hasPermission } from "../../../../utils/auth";
import { SidebarProps } from "../../../../models/layout";

const DeskTopNav: React.FC<SidebarProps> = ({ onClose, ...rest }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const popoverSubContentBgColor = useColorModeValue("pink.50", "gray.900");

  const [selectedMenu, setSelectedMenu] = React.useState("-");
  const [selectedFullPath, setSelectedFullPath] = React.useState("-");
  const isHideMenu = useSelector((state: any) => state.isHideMenu);
  const userRoles = useSelector((state: any) => state.userRoles);
  const specialRoutes = ["/", "/workbench"];
  useEffect(() => {
    //为了二级域名下面的隐藏菜单在左侧被大类选中, 切割地一个path
    let pathname = location.pathname;
    setSelectedFullPath(pathname);
    if (pathname.split("/").length > 2) {
      const firstPart = pathname.split("/")[1];
      pathname = `/${firstPart}`;
    }
    setSelectedMenu(pathname);
  }, [location.pathname]);

  const handleItemClick = (menuName: string) => {
    setSelectedMenu(menuName);
    setSelectedFullPath(menuName);
  };

  const handleSubItemClick = (subMenuName: string) => {
    setSelectedMenu(subMenuName);
    setSelectedFullPath(subMenuName);
  };
  const displayMenu = (lngStr: string) => {
    if (["/", "-"].includes(selectedMenu)) return t("menu.go-workbench");
    if (selectedMenu === "/workbench") return t("menu.go-home");
    return t("menu.go-home");
  };

  const displayPath = (path: string) => {
    if (path === "/") return "/workbench";
    if (path === "/workbench") return "/";
    return "/";
  };

  return (
    <Box
      display="flex"
      bg={useColorModeValue("white", "gray.900")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      {...rest}
    >
      <Box display="flex">
        {routes.map(
          (route, index) =>
            !route.isHide &&
            hasPermission(userRoles, route?.requiredRoles) && (
              <Box _focus={{ boxShadow: "none" }} key={index}>
                <Popover trigger={"hover"} placement={"bottom-start"}>
                  <PopoverTrigger>
                    <Box
                      px={{ base: 1, xl: 2 }}
                      mx={{ base: 1, xl: 2 }}
                      py="2"
                      _hover={{
                        cursor: "pointer",
                        color: "cyan.400",
                        borderColor: "cyan.400",
                      }}
                      borderBottom="2px solid"
                      className="menu-item"
                      borderColor={
                        selectedMenu === route.path ? "cyan.400" : "#fff"
                      }
                      color={
                        selectedMenu === route.path ? "cyan.400" : "inherit"
                      }
                      key={route.name}
                    >
                      {specialRoutes.includes(route.path) && (
                        <Link
                          as={RouterLink}
                          to={
                            route.children
                              ? undefined
                              : displayPath(selectedMenu)
                          }
                          display="flex"
                          alignItems="center"
                          style={{ textDecoration: "none" }}
                          onClick={() =>
                            !route.children &&
                            handleItemClick(displayPath(selectedMenu))
                          }
                        >
                          {!isHideMenu && <> {displayMenu(route.lngStr)}</>}
                        </Link>
                      )}
                      {!specialRoutes.includes(route.path) && (
                        <Link
                          as={RouterLink}
                          to={route.children ? undefined : route.path}
                          display="flex"
                          alignItems="center"
                          style={{ textDecoration: "none" }}
                          onClick={() =>
                            !route.children && handleItemClick(route.path)
                          }
                        >
                          {!isHideMenu && <> {t(route.lngStr)}</>}
                        </Link>
                      )}
                    </Box>
                  </PopoverTrigger>
                  {route.children && (
                    <PopoverContent
                      border={0}
                      boxShadow={"xl"}
                      bg={popoverContentBgColor}
                      py={4}
                      rounded={"xl"}
                      maxW="200px"
                    >
                      <Stack>
                        {route.children.map((child) => (
                          <Box
                            as="a"
                            href={child.path}
                            role={"group"}
                            display={"block"}
                            p={2}
                            rounded={"md"}
                            _hover={{ bg: popoverSubContentBgColor }}
                            bg={
                              selectedFullPath === child.path
                                ? popoverSubContentBgColor
                                : "inherit"
                            }
                          >
                            <Stack direction={"row"} align={"center"}>
                              <Box>
                                <Text
                                  transition={"all .3s ease"}
                                  _groupHover={{ color: "pink.400" }}
                                  fontWeight={500}
                                  color={
                                    selectedFullPath === child.path
                                      ? "pink.400"
                                      : "inherit"
                                  }
                                  onClick={() => handleSubItemClick(child.path)}
                                >
                                  {t(child.lngStr)}
                                </Text>
                              </Box>
                              <Flex
                                transition={"all .3s ease"}
                                transform={"translateX(-10px)"}
                                opacity={0}
                                _groupHover={{
                                  opacity: "100%",
                                  transform: "translateX(0)",
                                }}
                                justify={"flex-end"}
                                align={"center"}
                                flex={1}
                              >
                                <Icon
                                  color={"pink.400"}
                                  w={5}
                                  h={5}
                                  as={ChevronRightIcon}
                                />
                              </Flex>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    </PopoverContent>
                  )}
                </Popover>
              </Box>
            )
        )}
      </Box>
    </Box>
  );
};
export default DeskTopNav;
