import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationInfo } from "../../../models/auth";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import OrganizationEdit from "../Edit";
import { FiPlus } from "react-icons/fi";

const MyOrganizationList = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableList, setTableList] = useState<OrganizationInfo[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>(
    {} as OrganizationInfo
  );

  const initOrganization = {} as OrganizationInfo;
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const _info = JSON.parse(userInfo);
      setTableList(_info.organizations);
    }
  };

  const onConfirm = () => {
    init();
  };

  const tableFiled = [
    t("organization.list.name"),
    t("organization.list.number"),
    t("organization.list.description"),
    t("organization.list.role"),
    t("common.btn.actions"),
  ];

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td py="0.5rem" pl="0.2rem" {...extraProps}>
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: OrganizationInfo, index: number) => {
    return (
      <Tr
        key={index}
        // _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row.name, "sm", "bold", { minW: "240px" })}
        {_renderCell(row.number, "sm", "normal", { minW: "200px" })}
        {_renderCell(row.description, "sm", "normal", { minW: "300px" })}
        {_renderCell(row?.organizationRole, "sm", "normal")}
        <Td pl="0.2rem">
          <Flex>
            {row.organizationRole === "Admin" && (
              <Button
                size="sm"
                colorScheme="teal"
                mr="0.5rem"
                onClick={() => {
                  setOrganizationInfo(row);
                  onOpen();
                }}
              >
                {t("common.btn.edit")}
              </Button>
            )}
            <Button
              size="sm"
              onClick={() =>
                navigate(`/workbench/organization/members/${row.id}`)
              }
            >
              {t("organization.members.title")}
            </Button>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <Box>
      <Box bg={"#fff"} p="1rem" mb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold" mb="1rem">
          {t("organization.invite.title")}
        </Text>
        <Button
          leftIcon={<FiPlus />}
          colorScheme="teal"
          variant="solid"
          size="md"
          onClick={() => {
            setOrganizationInfo(initOrganization);
            onOpen();
          }}
        >
          {t("organization.list.create")}
        </Button>
      </Box>
      <Box
        overflowX={{ base: "scroll", md: "hidden" }}
        bgColor="#fff"
        p="1rem 1rem 2rem 1rem"
      >
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: OrganizationInfo, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>

        {isOpen && (
          <OrganizationEdit
            isOpen={isOpen}
            onClose={onClose}
            organizationInfo={organizationInfo}
            onConfirm={onConfirm}
          ></OrganizationEdit>
        )}
      </Box>
    </Box>
  );
};

export default MyOrganizationList;
