import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Blog } from "../../../models/blog";
import { FiPlus } from "react-icons/fi";
import { canEdit } from "../../../utils/auth";
import { deleteBlog, getBlogList } from "../../../services/blogService";

interface BlogsProps {
  isEdit: boolean;
}
const BlogsList: React.FC<BlogsProps> = ({ isEdit }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [tableList, setTableList] = useState<Blog[]>([]);
  const [deleteId, setDeleteId] = useState<string>();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const tableFiled = [
    t("blogs.title"),
    t("common.info.created-by"),
    t("common.info.created-date"),
    t("common.info.updated-date"),
  ];

  if (isEdit) {
    tableFiled.push(t("common.status"));
    tableFiled.push(t("common.btn.actions"));
  }

  useEffect(() => {
    fetchBlogList();
  }, []);

  const fetchBlogList = () => {
    const queryData = isEdit ? { isMine: true } : undefined;
    getBlogList(queryData).then((res) => {
      setTableList(res);
    });
  };
  const handleDeleteBlog = () => {
    deleteBlog(deleteId!)
      .then((res) => {
        fetchBlogList();
        onDeleteModalClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const _renderCell = (
    row: Blog,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
        onClick={() => navigate(`/articles/blogs/details/${row.id}`)}
      >
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderStatus = (isDraft: boolean) => {
    return isDraft ? t("common.draft") : t("common.published");
  };
  const _renderTableRow = (row: Blog, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.title, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.createrOrganizationName, "sm", "normal")}
        {_renderCell(row, row.createdDate?.replace("T", " "), "sm", "normal")}
        {_renderCell(row, row.updatedDate?.replace("T", " "), "sm", "normal")}
        {isEdit && (
          <>
            {_renderCell(row, _renderStatus(row?.isDraft!), "sm", "normal", {
              color: row.isDraft ? "gray" : "teal",
            })}
            <Td pl="0.2rem">
              <Flex>
                <Button
                  size="sm"
                  colorScheme="teal"
                  mr="0.5rem"
                  onClick={() => navigate(`/workbench/blogs/edit/${row.id}`)}
                >
                  {t("common.btn.edit")}
                </Button>
                {row.isDraft && (
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => {
                      setDeleteId(row.id);
                      onDeleteModalOpen();
                    }}
                  >
                    {t("common.btn.delete")}
                  </Button>
                )}
              </Flex>
            </Td>
          </>
        )}
      </Tr>
    );
  };

  return (
    <Box>
      {isEdit && (
        <Box mb="1rem" p="1rem" bgColor="#fff">
          <Button
            leftIcon={<FiPlus />}
            colorScheme="teal"
            variant="solid"
            isDisabled={!canEdit()}
            size="md"
            onClick={() => navigate(`/workbench/blogs/edit/new`)}
          >
            {t("blogs.create")}
          </Button>
        </Box>
      )}

      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: Blog, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("blogs.delete-modal.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("blogs.delete-modal.content")}</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onDeleteModalClose} size="sm">
              {t("common.btn.cancel")}
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleDeleteBlog()}
            >
              {t("common.btn.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BlogsList;
