import React from "react";
import { Box } from "@chakra-ui/react";
import MyOrganizationList from "../../Organizations/MyList/Index";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const WorkbenchOrganizations: React.FC = () => {
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.organization-management",
      path: "/workbench/organization-management",
    },
  ];
  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <MyOrganizationList></MyOrganizationList>
    </Box>
  );
};

export default WorkbenchOrganizations;
