import React, { useState, useRef, useId } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  Avatar,
  Button,
  Box,
  VStack,
  HStack,
  Center,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FiCamera } from "react-icons/fi";
import { uploadSingleFile } from "../../../services/commonService";
import { useTranslation } from "react-i18next";

interface AvatarUploaderProps {
  onUploadFile: (url: string) => void;
}

// AvatarUploader 组件
const AvatarUploader: React.FC<AvatarUploaderProps> = ({ onUploadFile }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState<string | undefined>(undefined);
  const [croppedImage, setCroppedImage] = useState<string | undefined>(
    undefined
  );
  const [profileImage, setProfileImage] = useState<string | undefined>(
    undefined
  );
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const uniqueId = useId();
  const size = "16px";
  const rounded = "full";
  const maxSize = 10 * 1024 * 1024;

  const toast = useToast();
  const { t } = useTranslation();

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files[0].size > maxSize) {
        toast({
          title: "File is too large.",
          description: `The file size should not exceed ${
            maxSize / (1024 * 1024)
          }MB.`,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    onOpen();
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const getCroppedImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
    }
  };

  const uploadCroppedImage = async () => {
    if (croppedImage) {
      try {
        setIsUploading(true);
        const formData = new FormData();
        const file = await fetch(croppedImage)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new File([blob], "croppedImage.png", { type: "image/png" })
          );

        formData.append("file", file);
        formData.append("folderName", "profile");
        // 上传文件到服务器
        const response = await uploadSingleFile(formData);
        // 更新头像URL
        const uploadedUrl = response.url;
        setProfileImage(uploadedUrl);
        setIsUploading(false);
        onClose();
        //刷新页面
        onUploadFile(uploadedUrl);
      } catch (error) {
        toast({
          title: t("common.upload.upload-error-msg"),
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      }
    }
  };

  return (
    <Box>
      <Center
        w={size}
        h={size}
        as={chakra.label}
        htmlFor={`file-${uniqueId}`}
        // bg="whiteAlpha.500"
        // border="1px dashed gray"
        color="white"
        rounded={rounded}
        cursor="pointer"
        overflow="hidden"
        position="relative"
      >
        <Center
          position="absolute"
          w="100%"
          h="100%"
          _hover={{ bg: "blackAlpha.600" }}
        >
          <VStack>
            <FiCamera />
          </VStack>
        </Center>

        <chakra.input
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          id={`file-${uniqueId}`}
          onChange={onFileChange}
        />
      </Center>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box bg="white">
              <VStack spacing={4}>
                {/* Avatar展示 */}
                <Avatar size="xl" src={profileImage || croppedImage} />

                {/* 图片裁剪区域 */}
                {image && (
                  <Cropper
                    src={image}
                    style={{ height: 300, width: "100%" }}
                    aspectRatio={1} // 1:1 比例
                    guides={false}
                    viewMode={1}
                    dragMode="move"
                    cropBoxMovable={true}
                    cropBoxResizable={true}
                    ref={cropperRef}
                  />
                )}

                <HStack spacing={4}>
                  {/* 确认裁剪按钮 */}
                  <Button
                    colorScheme="teal"
                    onClick={getCroppedImage}
                    // isDisabled={typeof cropperRef.current?.cropper === "undefined"}
                  >
                    {t("common.upload.crop")}
                  </Button>

                  {/* 上传裁剪后的图片 */}
                  {!!croppedImage && (
                    <Button
                      colorScheme="blue"
                      isLoading={isUploading}
                      onClick={uploadCroppedImage}
                    >
                      {t("common.upload.upload")}
                    </Button>
                  )}
                </HStack>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AvatarUploader;
