import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiArrowRightCircle } from "react-icons/fi";

const Step1 = () => {
  const { t } = useTranslation();
  return (
    <Box h="80%" w="360px" border="1px solid rgb(226, 232, 240)" p="2rem">
      <Text mb="1rem">{t("case.guide.step1.page")}</Text>
      <Box display="flex" mb="0.5rem">
        <Text py="0.5" px="1rem" borderBottom="2px solid grey">
          {t("case.guide.step1.category")} A
        </Text>
        <Text py="0.5" px="1rem">
          {t("case.guide.step1.category")} B
        </Text>
      </Box>
      <Box boxShadow="lg" minH="400px" px="0.5rem" py="1rem">
        <Box>
          <Box>
            <Box display="flex" justifyContent="space-between" mb="1rem">
              <Box> {t("case.guide.step1.date")}</Box>
              <Flex alignItems="center" justifyContent="center">
                <FiArrowRightCircle />
                <Text>{t("case.guide.step1.detail")} </Text>
              </Flex>
            </Box>
          </Box>
          <Heading size="lg" textAlign="center" mb="1rem">
            {t("case.guide.step1.title")}
          </Heading>
          <Box>
            <Text>{t("case.guide.step1.desc")}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step1;
