import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import DispatchList from "./List";

const WorkbenchDispatch = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    { label: "menu.dispatch", path: "/workbench/dispatch" },
  ];
  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" pb="0">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.dispatch")}
        </Text>
      </Box>
      <DispatchList isEdit={true}></DispatchList>
    </Box>
  );
};

export default WorkbenchDispatch;
