import { isUndefined } from "lodash";
import { AuthInfo, UserInfo } from "../models/auth";

export const hasPermission = (
  userRoles: string[],
  requiredRoles: string[] | undefined
) => {
  if (isUndefined(requiredRoles)) return true;
  return requiredRoles.some((role) => userRoles.includes(role));
};

export const canAccess = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!) as UserInfo;
  if (!!userInfo.currentOrganizationId) {
    return true;
  } else {
    return false;
  }
};

export const canEdit = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!) as UserInfo;
  if (!userInfo.currentOrganizationId) return false;
  const organizations = userInfo.organizations;
  const currentOrganization = organizations.find(
    (organization) => organization.id === userInfo.currentOrganizationId
  );
  return currentOrganization
    ? currentOrganization.organizationRole === "Admin"
    : false;
};

//当前id和userInfo.id相同就可以编辑
export const canEditProfile = (id: string) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!) as UserInfo;
  return userInfo.id === id;
};

export const isPlatformAdmin = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!) as UserInfo;
  const roles = userInfo.roles;
  return roles.includes("Admin");
};

//没token或者是Guest 就可以展示登录按钮, 并且自动登录
export const isGuest = () => {
  const token = localStorage.getItem("token");
  if (!token) return true;
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!) as UserInfo;
  const roles = userInfo.roles;
  return roles.includes("Guest");
};

export const setLocalInfo = (res: AuthInfo) => {
  localStorage.setItem("token", res.token);
  const userInfo = res.user;
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};
