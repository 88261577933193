import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { ReleaseDIgitalWorkforceTask } from "../../../../../models/releaseDigitalWorkforce";
import { releaseDigitalWorkforceTaskValidationSchema } from "../../../../../utils/validation";
import { createReleaseDigitalWorkforceTask } from "../../../../../services/releaseDigitalWorkforce";

interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: ReleaseDIgitalWorkforceTask;
  onSubmit: () => void;
}

const EditReleaseDigitalWorkforceTask: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    createReleaseDigitalWorkforceTask(values)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {!!initialValues.id
            ? t("release-digital-workforce.task.edit")
            : t("release-digital-workforce.task.create")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={releaseDigitalWorkforceTaskValidationSchema}
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Box my="0.5rem">
                  <Field name="functionName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.functionName && form.touched.functionName
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.task.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.functionName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="description">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.description && form.touched.description
                        }
                      >
                        <FormLabel>
                          {t("release-digital-workforce.task.desc")}:
                        </FormLabel>
                        <Textarea {...field} minH="10rem" />
                        <FormErrorMessage>
                          {form.errors.description}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="command">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.command && form.touched.command}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.task.command")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.command}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditReleaseDigitalWorkforceTask;
