import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Empty from "../../../components/Empty";
import { UserInfo } from "../../../models/auth";
import { cancelSubscribeDigitalWorkforce, getDigitalWorkforceById, subscribeDigitalWorkforce } from "../../../services/digitalWorkforceService";
import { DigitalWorkforceRow } from "../../../models/digitalWorkforce";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { formatCartCount, getCurrentOrganizationId, getUserInfo, parseCurrency } from "../../../utils/common";
import { addToMyCart, getCartList } from "../../../services/cartService";
import currency from "../../../assets/data/currency.json";
import { CartCountsContext } from "../../../App";

const DigitalWorkforceDetails: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState<any>();
  const [digitalWorkforceInfo, setDigitalWorkforceInfo] =
    useState<DigitalWorkforceRow>();
  const params = useParams();
  const id = params.id;
  const { setCartCounts} = useContext(CartCountsContext);

  useEffect(() => {
    fetchDigitalWorkforceDetail();
  }, []);

  const fetchDigitalWorkforceDetail = () => {
    if(!!id) {
      getDigitalWorkforceById(id).then((res) => {
        setDigitalWorkforceInfo(res);
        setBreadcrumbRoutes([
          { label: "menu.home", path: "/" },
          { label: "menu.digital-workforce", path: "/digital-workforce" },
          {
            label: res.name,
            path: res.name,
          },
        ]);
      });
    }
  };

  const addToCart =(groupId: string) => {
    addToMyCart([{digitalWorkforceId: id || '', groupId }]).then(res => {
      toast({
        title: t('cart.message.success'),
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: true,
      });
      getCartList().then((res) => {
        if(res) {
          setCartCounts(formatCartCount(res.length));
        };
       });
    }).catch(()=>{
      toast({
        title: t('cart.message.fail'),
        status: 'error',
        duration: 3000,
        position: 'top',
        isClosable: true,
      })
    });
    fetchDigitalWorkforceDetail();
  }

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      {!!breadcrumbRoutes && (
        <Box py="1rem">
          <CustomBreadcrumb routes={breadcrumbRoutes} />
        </Box>
      )}

      {!!digitalWorkforceInfo?.name ? (
        <Box p="1rem" pb="3rem" bgColor="#fff">
          <Center>
            <Heading as="h5" size="lg" py="2rem" fontWeight="bold">
              {digitalWorkforceInfo?.name}
            </Heading>
          </Center>
          <Box my="1rem">
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
            {digitalWorkforceInfo.groups?.map((row, index) => {
              return (
                <Card>
                  <Image data-type='Image'
                      objectFit='cover'
                      maxW='100%'
                      src='https://images.unsplash.com/photo-1620325867502-221cfb5faa5f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvamVjdHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=2000&q=60'
                      alt='Work'
                    />
                    {
                      row.subscribed && <Flex position={'absolute'} top={'10px'} right={'10px'} fontSize="0.8rem">
                      <Badge data-type="Badge" colorScheme="purple">{t('digital-workforce.list.subscribed')}</Badge>
                  </Flex> 
                    } 
                  <Stack mt='6' spacing='3'>
                    <CardBody>
                      <Heading size='md'>{row.groupName}</Heading>
                      <Text mt='3'>
                        {row.groupDescription}
                      </Text>
                      <Stack data-type='Stack' direction='row' mt='3'>
                        <Badge data-type='Badge' colorScheme='green'> {parseCurrency(row.cost, row.currency)}/{row.costType ? 'release-digital-workforce.function.costType.byMonth' : t('release-digital-workforce.function.costType.byTime')}</Badge>
                    </Stack>
                    </CardBody>                  
                </Stack>
                <CardFooter>
                  {
                    !row.subscribed &&
                    <ButtonGroup spacing='2'>
                    <Button colorScheme="teal" onClick={()=>{addToCart(row.id!)}} disabled={row.addToCart}>
                      {row.addToCart ? t('cart.added') : t('cart.add-to-cart')}
                    </Button>
                  </ButtonGroup>   
                  }              
                </CardFooter>
                </Card>
              );
            })}
          </SimpleGrid>
      </Box>
        </Box>
      ) : (
        <Empty></Empty>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="success">
              <AlertIcon />
              <Box>{t("digital-workforce.msg.subscribe-success-alert")}</Box>
              <Center>
                <Button
                  onClick={() => navigate("/workbench/digital-workforce")}
                >
                  {t("common.btn.go")}
                </Button>
              </Center>
            </Alert>
            <ModalFooter></ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DigitalWorkforceDetails;
