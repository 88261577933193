import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import zhLang from './locales/zh-CN.json'
import enLang from './locales/en-US.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        en: {
            translation: enLang,
        },
        zh: {
            translation: zhLang,
        }
    },
    detection: {
      order: ['navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  });
  export default i18n;