import {
  CheckIcon,
  EditIcon,
  EmailIcon,
  CloseIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  HStack,
  Heading,
  VStack,
  Text,
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  useToast,
  IconButton,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TimezoneSelect from "react-timezone-select";
import {
  bindEmail,
  removeEmail,
  resetPassword,
  updatePrimaryEmail,
  updateUserInfo,
  userInfo,
  verificationEmail,
} from "../../../services/authService";
import {
  BindEmailForm,
  ProfileInfo,
  ResetPasswordForm,
} from "../../../models/user";
import {
  bindEmailValidationSchema,
  resetPasswordValidationSchema,
} from "../../../utils/validation";
import { canEditProfile } from "../../../utils/auth";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import "cropperjs/dist/cropper.css";
import AvatarUploader from "./upload";

const Profile = () => {
  const { t } = useTranslation();

  const [profileInfo, setProfileInfo] = useState<ProfileInfo>();
  const [originalInfo, setOriginalInfo] = useState<ProfileInfo>();
  const [editingField, setEditingField] = useState<string>("");
  const [loading, setLoading] = useState(false); // 控制按钮的加载状态
  const [phoneValue, setPhoneValue] = useState<any>();
  const [primaryEmail, setPrimaryEmail] = useState<string>();
  const [secondaryEmails, setSecondaryEmails] = useState<string[]>();
  const [stopCountdown, setStopCountdown] = useState(0);

  const toast = useToast();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    {
      label: "menu.profile",
      path: "/profile",
    },
  ];
  const initResetPassword = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const initBindEmail = {
    email: "",
    verificationCode: "",
  };

  const {
    isOpen: isResetOpen,
    onOpen: onResetOpen,
    onClose: onResetClose,
  } = useDisclosure();

  const {
    isOpen: isAddEmailOpen,
    onOpen: onAddEmailOpen,
    onClose: onAddEmailClose,
  } = useDisclosure();

  const genderOptions = [
    { value: "Male", label: t("common.gender.male") },
    { value: "Female", label: t("common.gender.female") },
    { value: "Other", label: t("common.gender.other") },
  ];

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    if (!!id) {
      fetchProfileInfo();
    }
  }, []);

  useEffect(() => {
    setProfileInfo({ ...profileInfo!, phone: phoneValue });
  }, [phoneValue]);

  const fetchProfileInfo = async () => {
    userInfo(id!).then((res: ProfileInfo) => {
      setProfileInfo(res);
      setOriginalInfo(res);
      const primary = res.emails.find((emailObj) => emailObj.isPrimary)?.email;
      setPrimaryEmail(primary);
      // 提取 secondaryEmails
      const secondary = res.emails
        .filter((emailObj) => !emailObj.isPrimary)
        .map((emailObj) => emailObj.email);
      setSecondaryEmails(secondary);
    });
  };

  const handleSave = () => {
    const { id, emails, ...dataToSubmit } = profileInfo!;
    handleUpdateUserInfo(dataToSubmit);
  };

  const handleUpdateUserInfo = (data: any) => {
    setLoading(true);
    updateUserInfo(data)
      .then((res) => {
        fetchProfileInfo();
        setLoading(false);
        setEditingField("");
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch(() => {
        setLoading(false);
        toast({
          title: t("common.btn.update-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleChange = (e: { target: { value: any } }) => {
    setProfileInfo({ ...profileInfo!, [editingField!]: e.target.value });
  };

  const handleCancel = () => {
    setProfileInfo(originalInfo); // 恢复数据
    setEditingField(""); // 退出编辑模式
  };

  const handleResetPassword = (
    values: ResetPasswordForm,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (values.oldPassword === values.newPassword) {
      toast({
        title: t("settings.profile.same-password"),
        position: "top",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
      formikHelpers.setSubmitting(false);
      return;
    }
    resetPassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    })
      .then((res) => {
        fetchProfileInfo();
        onResetClose();
        formikHelpers.setSubmitting(false);
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        formikHelpers.setSubmitting(false);
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleSend = (email: string) => {
    setStopCountdown(60);
    const countdownInterval = setInterval(() => {
      setStopCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    verificationEmail(email)
      .then(() => {})
      .catch((error) => {
        setStopCountdown(0);
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleBindEmail = (
    values: BindEmailForm,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    bindEmail(values.email, values.verificationCode)
      .then((res) => {
        formikHelpers.setSubmitting(false);
        fetchProfileInfo();
        setEditingField("");
        onAddEmailClose();
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        formikHelpers.setSubmitting(false);
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const makePrimaryEmail = (email: string) => {
    updatePrimaryEmail({ email, isPrimary: true })
      .then((res) => {
        fetchProfileInfo();
        setEditingField("");
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: t("common.btn.update-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const removeSecondaryEmail = (email: string) => {
    removeEmail(email)
      .then((res) => {
        fetchProfileInfo();
        setEditingField("");
        toast({
          title: t("common.btn.update-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch(() => {
        toast({
          title: t("common.btn.update-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const handleUploadAvatar = (url: string) => {
    const _data = { ...profileInfo!, pictureUrl: url };
    setProfileInfo({ ...profileInfo!, pictureUrl: url });
    const { id, emails, ...dataToSubmit } = _data;
    handleUpdateUserInfo(dataToSubmit);
  };

  return (
    <Box maxW="1240px" margin="0 auto">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      {!canEditProfile(id!) ? (
        <Box maxW="1240px" bg="white">
          <Box p={8} maxW="sm" margin="0 auto" minH="500px">
            <VStack spacing={4}>
              <Avatar
                bg="teal.500"
                size="xl"
                name={profileInfo?.userName}
                src={profileInfo?.pictureUrl}
              />
              <FormControl>
                <Input
                  type="file"
                  accept="image/*"
                  // onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="avatar-upload"
                />
                <label htmlFor="avatar-upload">
                  <IconButton
                    icon={<SmallAddIcon />}
                    position="absolute"
                    bottom={0}
                    right={0}
                    colorScheme="teal"
                    aria-label="Upload Picture"
                    size="sm"
                    // isLoading={isUploading}
                    as="span"
                  />
                </label>
              </FormControl>
              <Heading as="h2" size="lg">
                {profileInfo?.userName}
              </Heading>
              <HStack spacing={2}>
                <EmailIcon />
                <Text>{primaryEmail}</Text>
              </HStack>
              <Text textAlign="center">{profileInfo?.description}</Text>
            </VStack>
          </Box>
        </Box>
      ) : (
        <Box maxW="1240px" bg="white" mb="2rem">
          <Flex justifyContent="center">
            <Flex
              direction={{ base: "column", md: "row" }}
              w={{ base: "100%", md: "80%" }}
              mt="1rem"
              p="4"
            >
              <Box w={{ base: "100%", md: "55%" }} pb="1rem">
                <VStack spacing="4" align="stretch">
                  {/* 头像 */}
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.avatar")}:
                    </Text>
                    <Box position="relative">
                      <Avatar
                        size="md"
                        name={profileInfo?.userName}
                        src={profileInfo?.pictureUrl}
                      />
                      <Box position="absolute" top="30px" left="16px">
                        <AvatarUploader
                          onUploadFile={handleUploadAvatar}
                        ></AvatarUploader>
                      </Box>
                    </Box>
                  </HStack>

                  {/* Full Name */}
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.full-name")}:
                    </Text>
                    {editingField === "userName" ? (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Input
                          value={profileInfo?.userName}
                          onChange={handleChange}
                        />
                        <HStack
                          mt={{ base: "0.5rem", md: "0" }}
                          ml={{ base: "0", md: "0.5rem" }}
                        >
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CheckIcon />}
                            onClick={handleSave}
                            isLoading={loading}
                            colorScheme="teal"
                          />
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CloseIcon />}
                            onClick={handleCancel}
                            colorScheme="red"
                            isDisabled={loading}
                          />
                        </HStack>
                      </Flex>
                    ) : (
                      <HStack>
                        <Text>{profileInfo?.userName}</Text>
                        <IconButton
                          size="sm"
                          aria-label="left-arrow"
                          icon={<EditIcon />}
                          onClick={() => setEditingField("userName")}
                          variant="ghost"
                          isDisabled={loading}
                        />
                      </HStack>
                    )}
                  </HStack>

                  {/* Gender */}
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.gender")}:
                    </Text>
                    {editingField === "gender" ? (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Select
                          value={profileInfo?.gender}
                          onChange={handleChange}
                          minW="244px"
                        >
                          {genderOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                        <HStack
                          mt={{ base: "0.5rem", md: "0" }}
                          ml={{ base: "0", md: "0.5rem" }}
                        >
                          <IconButton
                            size="sm"
                            aria-label="save"
                            icon={<CheckIcon />}
                            onClick={handleSave}
                            colorScheme="teal"
                            isLoading={loading}
                          />
                          <IconButton
                            size="sm"
                            aria-label="cancel"
                            icon={<CloseIcon />}
                            onClick={handleCancel}
                            colorScheme="red"
                            isDisabled={loading}
                          />
                        </HStack>
                      </Flex>
                    ) : (
                      <HStack>
                        <Text>
                          {
                            genderOptions.find(
                              (option) => option.value === profileInfo?.gender
                            )?.label
                          }
                        </Text>
                        <IconButton
                          size="sm"
                          aria-label="edit"
                          icon={<EditIcon />}
                          onClick={() => setEditingField("gender")}
                          variant="ghost"
                          isDisabled={loading}
                        />
                      </HStack>
                    )}
                  </HStack>

                  {/* description */}
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.desc")}:
                    </Text>
                    {editingField === "description" ? (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Textarea
                          value={profileInfo?.description}
                          onChange={handleChange}
                          minW={{ base: "200px", md: "300px" }}
                          minH="200px"
                        />
                        <HStack
                          mt={{ base: "0.5rem", md: "0" }}
                          ml={{ base: "0", md: "0.5rem" }}
                        >
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CheckIcon />}
                            onClick={handleSave}
                            colorScheme="teal"
                            isLoading={loading}
                          />
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CloseIcon />}
                            onClick={handleCancel}
                            colorScheme="red"
                            isDisabled={loading}
                          />
                        </HStack>
                      </Flex>
                    ) : (
                      <HStack>
                        <Text>{profileInfo?.description}</Text>
                        <IconButton
                          size="sm"
                          aria-label="left-arrow"
                          icon={<EditIcon />}
                          onClick={() => setEditingField("description")}
                          variant="ghost"
                          isDisabled={loading}
                        />
                      </HStack>
                    )}
                  </HStack>
                  {/* phone */}

                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.phone")}:
                    </Text>
                    {editingField === "phone" ? (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phoneValue}
                          onChange={setPhoneValue}
                          defaultCountry="US"
                        />
                        <HStack
                          mt={{ base: "0.5rem", md: "0" }}
                          ml={{ base: "0", md: "0.5rem" }}
                        >
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CheckIcon />}
                            onClick={handleSave}
                            colorScheme="teal"
                            isLoading={loading}
                          />
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CloseIcon />}
                            onClick={handleCancel}
                            colorScheme="red"
                            isDisabled={loading}
                          />
                        </HStack>
                      </Flex>
                    ) : (
                      <HStack>
                        <Text>{profileInfo?.phone}</Text>
                        <IconButton
                          size="sm"
                          aria-label="left-arrow"
                          icon={<EditIcon />}
                          onClick={() => setEditingField("phone")}
                          variant="ghost"
                          isDisabled={loading}
                        />
                      </HStack>
                    )}
                  </HStack>

                  {/* Time Zone */}
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.time-zone")}:
                    </Text>
                    {editingField === "timeZone" ? (
                      <Flex direction={{ base: "column", md: "row" }}>
                        <Box minW="244px">
                          <TimezoneSelect
                            value={profileInfo?.timeZone as any}
                            onChange={(selectedTimezone) =>
                              setProfileInfo({
                                ...profileInfo!,
                                timeZone: selectedTimezone.value,
                              })
                            }
                          />
                        </Box>
                        <HStack
                          mt={{ base: "0.5rem", md: "0" }}
                          ml={{ base: "0", md: "0.5rem" }}
                        >
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CheckIcon />}
                            onClick={handleSave}
                            colorScheme="teal"
                            isLoading={loading}
                          />
                          <IconButton
                            size="sm"
                            aria-label="left-arrow"
                            icon={<CloseIcon />}
                            onClick={handleCancel}
                            colorScheme="red"
                            isDisabled={loading}
                          />
                        </HStack>
                      </Flex>
                    ) : (
                      <HStack>
                        <Text>{profileInfo?.timeZone}</Text>
                        <IconButton
                          size="sm"
                          aria-label="left-arrow"
                          icon={<EditIcon />}
                          onClick={() => setEditingField("timeZone")}
                          variant="ghost"
                          isDisabled={loading}
                        />
                      </HStack>
                    )}
                  </HStack>
                </VStack>
              </Box>
              <Box w={{ base: "100%", md: "45%" }} pl={{ base: 0, md: "1rem" }}>
                {/* Emails */}
                <VStack spacing="4" align="stretch">
                  <HStack>
                    <Text fontWeight="bold" minW="95px">
                      {t("settings.profile.primary-email")}:
                    </Text>
                    <Text>{primaryEmail}</Text>
                  </HStack>
                  <HStack>
                    <VStack spacing="4" align="stretch">
                      <Text fontWeight="bold" minW="95px">
                        {t("settings.profile.secondary-email")}:
                      </Text>
                      {secondaryEmails?.map((email, index) => {
                        return (
                          <Flex>
                            <Text key={index} minW="180px" color="gray">
                              {email}
                            </Text>
                            <Flex direction={{ base: "column", md: "row" }}>
                              <Button
                                size="sm"
                                colorScheme="blue"
                                variant="link"
                                ml="0.5rem"
                                mb={{ base: "0.5rem", md: 0 }}
                                onClick={() => makePrimaryEmail(email)}
                              >
                                {t("settings.profile.make-primary")}
                              </Button>
                              <Button
                                size="sm"
                                colorScheme="blue"
                                variant="link"
                                ml="0.5rem"
                                onClick={() => removeSecondaryEmail(email)}
                              >
                                {t("common.btn.remove")}
                              </Button>
                            </Flex>
                          </Flex>
                        );
                      })}
                      <Button
                        w="200px"
                        leftIcon={<FiPlus />}
                        colorScheme="green"
                        variant="solid"
                        size="sm"
                        onClick={() => onAddEmailOpen()}
                      >
                        {t("settings.profile.add-email")}
                      </Button>
                    </VStack>
                  </HStack>
                  {canEditProfile(id!) && (
                    <HStack pt={{ base: "2rem", md: "4rem" }}>
                      <Text fontWeight="bold" minW="95px">
                        {t("settings.profile.password")}:
                      </Text>
                      <Text>******</Text>
                      <Button size="sm" ml="1rem" onClick={onResetOpen}>
                        {t("settings.profile.reset-password")}
                      </Button>
                    </HStack>
                  )}
                </VStack>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}

      <Modal isOpen={isResetOpen} onClose={onResetClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("settings.profile.reset-password")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initResetPassword}
              validationSchema={resetPasswordValidationSchema}
              onSubmit={handleResetPassword}
            >
              {(props) => (
                <Form>
                  <Field name="oldPassword">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.oldPassword && form.touched.oldPassword
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("settings.profile.old-password")}
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.oldPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="newPassword">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.newPassword && form.touched.newPassword
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("settings.profile.new-password")} :
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.newPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.confirmPassword &&
                          form.touched.confirmPassword
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("settings.profile.confirm-password")} :
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    type="submit"
                    size="sm"
                  >
                    {t("settings.profile.confirm-password")}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddEmailOpen} onClose={onAddEmailClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("settings.profile.bind-email.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initBindEmail}
              validationSchema={bindEmailValidationSchema}
              onSubmit={handleBindEmail}
            >
              {(props) => (
                <Form>
                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel>
                          {t("settings.profile.bind-email.email")}
                        </FormLabel>
                        <Input
                          {...field}
                          placeholder={t(
                            "settings.profile.bind-email.email-placeholder"
                          )}
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="verificationCode">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.verificationCode &&
                          form.touched.verificationCode
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("settings.profile.bind-email.verification-code")} :
                        </FormLabel>
                        <InputGroup size="md">
                          <Input
                            {...field}
                            placeholder={t(
                              "settings.profile.bind-email.verification-code-placeholder"
                            )}
                          />
                          <InputRightElement width="6rem">
                            <Button
                              h="1.75rem"
                              colorScheme="blue"
                              // size="sm"
                              onClick={() => handleSend(form.values.email)}
                              isDisabled={
                                !!form.errors.email ||
                                !form.values.email ||
                                stopCountdown > 0
                              }
                            >
                              {stopCountdown > 0
                                ? t("settings.profile.bind-email.send") +
                                  `(${stopCountdown}s)`
                                : t("settings.profile.bind-email.send")}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.verificationCode}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    type="submit"
                    size="sm"
                  >
                    {t("settings.profile.bind-email.bind")}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile;
