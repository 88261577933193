import { configureStore, createSlice } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  isHideMenu: false,
  isAdmin: false,
  userRoles: [],
  userInfo:null,
};

const { reducer, actions } = createSlice({
  name: "visibility",
  initialState,
  reducers: {
    toggleHide: (state) => {
      state.isHideMenu = !state.isHideMenu;
    },
    setAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
export const { toggleHide, setAdmin, setUserRoles ,setUserInfo} = actions;
