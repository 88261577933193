import { useEffect, useState } from "react";
import { organizationList } from "../../../services/organizationService";
import { useTranslation } from "react-i18next";
import { OrganizationInfo } from "../../../models/auth";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const OrganizationList = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableList, setTableList] = useState<OrganizationInfo[]>([]);

  useEffect(() => {
    organizationList().then((res) => {
      setTableList(res);
    });
  }, []);

  const tableFiled = [
    t("organization.list.name"),
    t("organization.list.number"),
    t("organization.list.description"),
  ];

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td pl="0.2rem" {...extraProps}>
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: OrganizationInfo, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
        onClick={() => navigate(`/organizations/details/${row.id}`)}
      >
        {_renderCell(row.name, "sm", "bold", { minW: "240px" })}
        {_renderCell(row.number, "sm", "normal", { minW: "200px" })}
        {_renderCell(row.description, "sm", "normal", { minW: "300px" })}
      </Tr>
    );
  };

  return (
    <Box
      overflowX={{ base: "scroll", md: "hidden" }}
      bgColor="#fff"
      p="1rem 1rem 2rem 1rem"
    >
      <Table variant="simple" color={textColor}>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            {tableFiled.map((caption, idx) => {
              return (
                <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {tableList.map((row: OrganizationInfo, index) => {
            return _renderTableRow(row, index);
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default OrganizationList;
