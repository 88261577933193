import {
  Box,
  Button,
  Card,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import { AuthInfo, OrganizationInfo } from "../../../models/auth";
import { organizationInfoValidationSchema } from "../../../utils/validation";
import { upsertOrganizationInfo } from "../../../services/userService";
import { userInitial } from "../../../services/authService";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../store";
import { setDefaultOrganization } from "../../../services/organizationService";
import { setLocalInfo } from "../../../utils/auth";

const Organization = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>(
    {} as OrganizationInfo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const _info = JSON.parse(userInfo);
      const organizationInfo = _info.organizations.find(
        (c: OrganizationInfo) => c.id === _info.currentOrganizationId
      );
      if (!!organizationInfo) {
        setOrganizationInfo(organizationInfo);
      }
    }
  }, []);

  const handleEditOrganizationInfo = () => {
    onOpen();
  };

  const handleSubmit = (
    values: OrganizationInfo,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let data = {
      name: values.name,
      description:
        typeof values.description !== "undefined" ? values.description : "",
      number: typeof values.number !== "undefined" ? values.number : 0,
    } as OrganizationInfo;

    if (values.id) {
      data.id = values.id;
    }

    upsertOrganizationInfo(data)
      .then((res) => {
        onClose();
        setOrganizationInfo(values);
        userInitial().then((res) => {
          updateLocalInfo(res.currentOrganizationId);
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((res) => {
        formikHelpers.setSubmitting(false);
      });
  };

  const updateLocalInfo = (id: string) => {
    setDefaultOrganization(id).then((res: AuthInfo) => {
      setLocalInfo(res);
      const userInfo = res.user;
      dispatch(setUserInfo(userInfo));
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <Card width="100%" p="1rem">
      <Box width="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            w="100%"
            display="flex"
            alignContent="center"
            alignItems="center"
          >
            <Heading as="h4" size="md">
              {t("organization.list.header")}
            </Heading>
            <Spacer />
            <FiEdit cursor="pointer" onClick={handleEditOrganizationInfo} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="1.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("organization.list.name")}
            </Heading>
            <Box width="100%" pt="6px" pl="6px" fontWeight="bold">
              {organizationInfo?.name}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("organization.list.number")}
            </Heading>
            <Box width="100%" pt="6px" pl="6px" fontWeight="bold">
              {organizationInfo?.number}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("organization.list.description")}
            </Heading>
            <Box
              width="100%"
              pt="6px"
              pl="6px"
              minHeight="30px"
              fontWeight="bold"
            >
              {organizationInfo?.description}
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("organization.list.header")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={organizationInfo}
              validationSchema={organizationInfoValidationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel> {t("organization.list.name")}</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="number">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.number && form.touched.number}
                      >
                        <FormLabel>
                          {" "}
                          {t("organization.list.number")} :
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.number}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="description">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.description && form.touched.description
                        }
                      >
                        <FormLabel>
                          {t("organization.list.description")} :
                        </FormLabel>
                        <Textarea {...field} minH="15rem" />
                        <FormErrorMessage>
                          {form.errors.description}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    isLoading={props.isSubmitting}
                    type="submit"
                    size="sm"
                  >
                    {t("common.btn.submit")}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Organization;
