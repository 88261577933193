import React from "react";
import { Box, VStack, Text } from "@chakra-ui/react";
import { HISTORICAL_LOGS } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const Logs: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    {
      label: "menu.logs",
      path: "/logs",
    },
  ];
  return (
    <Box maxW="1240px" margin="0 auto" p={4}>
      <Box pb="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>

      <VStack spacing={4}>
        {HISTORICAL_LOGS.map((log, index) => (
          <Box
            key={index}
            p={2}
            w="100%"
            borderWidth={1}
            borderRadius="md"
            bgColor={index === 0 ? "teal.300" : "gray.100"}
            color={index === 0 ? "white" : "gray.800"}
          >
            <Text fontWeight="bold">
              {t("logs.version")}: {log.version}
            </Text>
            <Text>
              {t("logs.date")}: {log.date}
            </Text>

            <Text>{log.content}</Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Logs;
