import { Box, Text, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SimpleCarousel from "../../../../../components/Carousel/Simple";

const Step2 = () => {
  const { t } = useTranslation();

  const content = [
    t("case.guide.step2.challenge"),
    t("case.guide.step2.solution"),
    t("case.guide.step2.key-deliverables"),
  ];
  return (
    <Box minH="80%" w="360px" border="1px solid rgb(226, 232, 240)" p="2rem">
      <Text mb="1rem">{t("case.guide.step2.page")}</Text>
      <Box boxShadow="lg" minH="400px" px="0.5rem" py="1rem">
        <Box>
          <Heading size="md" textAlign="center" mb="1rem">
            {t("case.guide.step1.title")}
          </Heading>
          <Box>
            <Text noOfLines={3}>{t("case.guide.step1.desc")}</Text>
          </Box>
        </Box>
        {content.map((item, index) => {
          return (
            <Box mt="0.5rem" key={index}>
              <Heading size="sm">{item}</Heading>
              <Text pl="1rem">
                <ul>
                  <li>xxx</li>
                  <li>xxx</li>
                </ul>
              </Text>
            </Box>
          );
        })}

        <Box mt="0.5rem">
          <Heading size="sm" mb="0.5rem">
            {t("case.guide.step2.images")}
          </Heading>
          <SimpleCarousel />
        </Box>
      </Box>
    </Box>
  );
};

export default Step2;
