import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useToast,
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Spinner,
  Center,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Code,
} from "@chakra-ui/react";
import {
  getSubscribedDigitalWorkforceById,
  getStatusIcon,
  getStatusText,
  enableSubscribedFunction,
  executeSubscribedFunction,
  disableSubscribedFunction,
  stopExecutedTask,
} from "../../../services/digitalWorkforceService";
import {
  CotinueOnError,
  SubscribedDigitalWorkforce,
  SubscribedWorkforceFunction,
  SubscribedWorkforceFunctionsGroup,
  TaskStatus,
} from "../../../models/digitalWorkforce";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const SubscribedDigitalWorkforceDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<SubscribedDigitalWorkforce | null>(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [stopCountdown, setStopCountdown] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const loadData = async () => {
    setLoading(true);
    if (id) {
      const data = await getSubscribedDigitalWorkforceById(id);
      setData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleAction = async (
    action: "enable" | "disable" | "execute" | "stop",
    groupId: string,
    taskGroupId: string
  ) => {
    setLoading(true);
    try {
      switch (action) {
        case "enable":
          await enableSubscribedFunction(groupId);
          break;
        case "disable":
          await disableSubscribedFunction(groupId);
          break;
        case "execute":
          await executeSubscribedFunction(groupId);
          break;
        case "stop":
          await stopExecutedTask({
            taskGroupId: taskGroupId,
            subScribedWorkforceFunctionGroupId: groupId,
          });
          toast({
            title:
              "Stop Command has been sent successfully, please wait for the task to stop",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setStopCountdown(60); // 启动倒计时
          const countdownInterval = setInterval(() => {
            setStopCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(countdownInterval);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
          break;
      }
      toast({
        title: `${action.charAt(0).toUpperCase() + action.slice(1)} successful`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      loadData(); // 重新加载数据以反映更改
    } catch (error) {
      console.error(`${action} error:`, error);
      toast({
        title: `${action.charAt(0).toUpperCase() + action.slice(1)} failed`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const toggleDesc = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <Box maxW="1240px" margin="0 auto">
        {loading && ( // 根据loading状态显示或隐藏遮罩
          <Center
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="#fff"
            zIndex="overlay"
          >
            <Spinner size="xl" />
          </Center>
        )}
        <Box p="4">
          {data ? (
            <>
              <VStack align="start" spacing="4" bg="#fff" p="1rem">
                <Heading>
                  {t("common.status")}: {getStatusText(data.activityStatus)}
                  {getStatusIcon(data.activityStatus)}
                </Heading>
                <HStack justifyContent="space-between" width="full">
                  <Box width="45%">
                    <Text>
                      {t("digital-workforce.task.v-host")}: {data.vhost}
                    </Text>
                    <Text>
                      {t("digital-workforce.task.queue-name")}: {data.queueName}
                    </Text>
                  </Box>
                </HStack>
                <HStack justifyContent="space-between" width="full">
                  <Box width="45%">
                    <Text>
                      {t("digital-workforce.task.log-vhost")}: {data.logVhost}
                    </Text>
                    <Text>
                      {t("digital-workforce.task.log-queue-name")}:
                      {data.logQueueName}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
              <Heading size="md" mt="6">
                {t("digital-workforce.task.functions")}
              </Heading>
              {data &&
                data.subscribedWorkforceFunctionsGroups &&
                data.subscribedWorkforceFunctionsGroups.map(
                  (fung: SubscribedWorkforceFunctionsGroup, index) => (
                    <Box
                      key={fung.id}
                      p="4"
                      borderWidth="2px"
                      borderRadius="lg"
                      mt="4"
                      bg="#fff"
                    >
                      <Flex justifyContent="space-between">
                        <Text fontSize="xl" fontWeight="bold">
                          {fung.groupName}
                        </Text>
                        <Button onClick={() => toggleDesc(index)} size="sm">
                          {expandedIndex === index
                            ? t("common.btn.collapse")
                            : t("common.btn.expand")}
                        </Button>
                      </Flex>

                      {expandedIndex === index && (
                        <Box>
                          <HStack spacing="4">
                            <Text fontSize="md" color="gray.500">
                              {fung.groupDescription}
                            </Text>
                          </HStack>
                          <Flex>
                            <Text color="gray.500" mr="1rem">
                              {t("digital-workforce.task.working-status")}:
                            </Text>
                            <Text>
                              {getStatusText(fung.groupActivityStatus)}
                            </Text>
                          </Flex>

                          <Flex>
                            <Text color="gray.500" mr="1rem">
                              {t("digital-workforce.task.execute-type")}:
                            </Text>
                            <Text mr="1rem">
                              {fung.executeType === 0 ? "Manual" : "Cron"}
                            </Text>
                            {fung.cronStr && (
                              <Code colorScheme="red">{fung.cronStr}</Code>
                            )}
                          </Flex>

                          {fung.executeType === 1 && fung.cronStr && (
                            <Flex>
                              <Text color="gray.500" mr="1rem">
                                {t("digital-workforce.task.next-run-time")}:
                              </Text>
                              <Text>
                                {fung.nextRun
                                  ? moment
                                      .utc(fung.nextRun)
                                      .tz(fung.timeZoneId)
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  : ""}
                              </Text>
                            </Flex>
                          )}
                          <Flex>
                            <Text color="gray.500" mr="1rem">
                              {t("common.status")}:
                            </Text>
                            <Text color={fung.status === 0 ? "red" : "teal"}>
                              {fung.status === 0
                                ? t("common.inactive")
                                : t("common.active")}
                            </Text>
                          </Flex>
                          <Box overflowX="scroll" maxW="100%">
                            <Table
                              variant="simple"
                              bg="gray.100"
                              border="1px"
                              borderColor="gray.200"
                            >
                              <Thead>
                                <Tr>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("digital-workforce.task.sequence")}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("digital-workforce.task.function-name")}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t(
                                      "digital-workforce.task.function-description"
                                    )}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t(
                                      "digital-workforce.task.continue-on-error"
                                    )}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("digital-workforce.task.command")}
                                  </Th>

                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t(
                                      "digital-workforce.task.last-task-created-time"
                                    )}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("digital-workforce.task.start-time")}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("digital-workforce.task.finish-time")}
                                  </Th>
                                  <Th
                                    border="1px"
                                    borderColor="gray.200"
                                    p="0.5rem"
                                  >
                                    {t("common.status")}
                                  </Th>
                                </Tr>
                              </Thead>
                              {fung.subscribedWorkforceFunctions &&
                                fung.subscribedWorkforceFunctions
                                  .sort((a, b) => a.sequence - b.sequence)
                                  .map((func: SubscribedWorkforceFunction) => (
                                    <Tbody key={func.id}>
                                      <Tr>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.sequence}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.functionName}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.functionDescription}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {CotinueOnError[func.continueOnError]}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.command}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.activeTasks?.createdTime
                                            ? new Date(
                                                func.activeTasks?.createdTime
                                              ).toLocaleString()
                                            : ""}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.activeTasks?.startTime
                                            ? new Date(
                                                func.activeTasks?.startTime
                                              ).toLocaleString()
                                            : ""}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {func.activeTasks?.finishTime
                                            ? new Date(
                                                func.activeTasks?.finishTime
                                              ).toLocaleString()
                                            : ""}
                                        </Td>
                                        <Td border="1px" borderColor="gray.200">
                                          {
                                            TaskStatus[
                                              func.activeTasks?.taskStatus
                                            ]
                                          }
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  ))}
                            </Table>
                          </Box>
                        </Box>
                      )}

                      <HStack spacing="4" pt="1rem">
                        {fung.status === 0 ? (
                          <Button
                            colorScheme="green"
                            size="sm"
                            onClick={() => handleAction("enable", fung.id, "")}
                          >
                            {t("common.enable")}
                          </Button>
                        ) : (
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => handleAction("disable", fung.id, "")}
                          >
                            {t("common.disable")}
                          </Button>
                        )}
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleAction("execute", fung.id, "")}
                        >
                          {t("common.trigger")}
                        </Button>
                        {fung.groupActivityStatus === TaskStatus.Working && (
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() =>
                              handleAction(
                                "stop",
                                fung.id,
                                fung.activeTaskGroup.taskGroupId
                              )
                            }
                            isDisabled={stopCountdown > 0}
                          >
                            {stopCountdown > 0
                              ? `Stop (${stopCountdown}s)`
                              : "Stop"}
                          </Button>
                        )}
                      </HStack>
                    </Box>
                  )
                )}
            </>
          ) : (
            <Text>Loading...</Text>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SubscribedDigitalWorkforceDetails;
