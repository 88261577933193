import {
  Box,
  Td,
  Tr,
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  useColorModeValue,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Requirement, TaskTimeline } from "../../../models/requirement";
import {
  getRequirementTimeLine,
  getRequirementsList,
} from "../../../services/requirementService";
import EditTaskTimeline from "../TaskTimeline/Edit";
import TaskTimelineDetails from "../TaskTimeline/Details";
import { canEdit } from "../../../utils/auth";

const MyApplyList: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const [taskTimelineInfo, setTaskTimelineInfo] = useState<TaskTimeline | null>(
    null
  );
  const [tableList, setTableList] = useState<Requirement[]>([]);
  const [initTaskTimelineValues, setInitTaskTimelineValues] =
    useState<TaskTimeline>({
      requirementId: "",
      estimate: 0,
      design: 0,
      development: 0,
      codeReview: 0,
      integrationTest: 0,
      inspect: 0,
      settlement: 0,
    });

  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const onCreateTaskTimeline = (id: string) => {
    setInitTaskTimelineValues({
      requirementId: id,
      estimate: 0,
      design: 0,
      development: 0,
      codeReview: 0,
      integrationTest: 0,
      inspect: 0,
      settlement: 0,
    });
    onCreateOpen();
  };

  const fetchData = () => {
    getRequirementsList({ takeByCurrentUser: true }).then((res) => {
      res.sort((a: Requirement, b: Requirement) => {
        const dateA = new Date(a.publishedDate);
        const dateB = new Date(b.publishedDate);
        return dateB.getTime() - dateA.getTime();
      });
      setTableList(res);
    });
  };

  const requirementStatus = (status: number) => {
    return t("requirements.list.status-" + status);
  };

  const handleSubmit = () => {
    fetchData();
  };

  const handleView = (taskTimelineId: string) => {
    getRequirementTimeLine(taskTimelineId).then((res) => {
      setTaskTimelineInfo(res);
      onViewOpen();
    });
  };

  const tableFiled = [
    t("requirements.create.title"),
    t("requirements.create.desc"),
    t("requirements.list.estimate"),
    t("requirements.list.status"),
    t("common.btn.actions"),
  ];

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td py="0.5rem" {...extraProps}>
        <Text
          fontSize={fontSize}
          fontWeight={fontWeight}
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Td>
    );
  };

  const _renderTableRow = (row: Requirement, index: number) => {
    return (
      <Tr key={index}>
        {_renderCell(row.title, "sm", "bold")}
        {_renderCell(row.description, "sm", "normal")}
        {_renderCell(row.estimatedTime, "sm", "normal")}
        {_renderCell(requirementStatus(row.status), "sm", "normal")}

        <Td py="0.5rem" display="flex" minH="40px">
          {row.status === 1 && (
            <>
              {!!row.taskTimelineId ? (
                <Button
                  size="sm"
                  colorScheme="gray"
                  onClick={() => handleView(row.taskTimelineId!)}
                >
                  {t("requirements.taskTimeline.title")}
                </Button>
              ) : (
                <Button
                  size="sm"
                  isDisabled={!canEdit()}
                  colorScheme="teal"
                  onClick={() => onCreateTaskTimeline(row.id)}
                >
                  {t("requirements.taskTimeline.add")}
                </Button>
              )}
            </>
          )}
        </Td>
      </Tr>
    );
  };

  return (
    <Box
      bgColor="#fff"
      p={{ base: "1rem", md: "2rem" }}
      overflowX={{ base: "scroll", md: "hidden" }}
    >
      <Table variant="simple" color={textColor}>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            {tableFiled.map((caption, idx) => {
              return (
                <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {tableList.map((row: Requirement, index) => {
            return _renderTableRow(row, index);
          })}
        </Tbody>
      </Table>
      {isCreateOpen && (
        <EditTaskTimeline
          isOpen={isCreateOpen}
          onOpen={onCreateOpen}
          onClose={onCreateClose}
          initialValues={initTaskTimelineValues}
          onRefresh={handleSubmit}
        ></EditTaskTimeline>
      )}

      {isViewOpen && taskTimelineInfo && (
        <TaskTimelineDetails
          isOpen={isViewOpen}
          onOpen={onViewOpen}
          onClose={onViewClose}
          taskTimelineInfo={taskTimelineInfo!}
        ></TaskTimelineDetails>
      )}
    </Box>
  );
};

export default MyApplyList;
