import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useColorModeValue,
  InputRightElement,
  InputGroup,
  useToast,
  Divider,
  AbsoluteCenter,
  Center,
  Stack,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { FiEye, FiEyeOff } from "react-icons/fi";
import BgSignUp from "../../../assets/img/BgSignUp.png";
import {
  AuthForm,
  AuthInfo,
  GoogleUserInfo,
  RegistrationInfo,
} from "../../../models/auth";
import { Formik, Form, Field, FieldProps } from "formik";
import { registerValidationSchema } from "../../../utils/validation";
import { useState } from "react";
import {
  fetchGoogleUserInfo,
  register,
  registrationVerification,
} from "../../../services/authService";
import { Heading } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { setLocalInfo } from "../../../utils/auth";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserRoles } from "../../../store";
import { isEmpty } from "lodash";

const Register = () => {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [registerInfo, setRegisterInfo] = useState<AuthForm>();
  const [pin, setPin] = useState("");
  const [stopCountdown, setStopCountdown] = useState(0);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (
    values: AuthForm,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    formikHelpers.setSubmitting(false);
    setShowVerify(true);
    setRegisterInfo({
      email: values.email,
      password: values.password,
    });
    sendEmail(values.email);
  };

  const registerWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      fetchGoogleUserInfo(tokenResponse.access_token)
        .then((res: GoogleUserInfo) => {
          setShowVerify(true);
          setRegisterInfo({
            email: res.email,
          });
          sendEmail(res.email);
        })
        .catch((error) => {
          console.error("Error fetching user information:", error);
        });
    },
    onError: (errorResponse) => console.log("Login Failed:", errorResponse),
  });

  //提交注册
  const confirmVerify = () => {
    register(registerInfo!)
      .then((res: RegistrationInfo) => {
        //注册成功去登陆页面目前，最好还是直接登陆
        toast({
          title: "Congratulations. You have registered successfully.",
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        navigate(`/login`);
      })
      .catch((error) => {
        toast({
          title: error.response.data,
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  //发送邮件
  const sendEmail = (email: string) => {
    registrationVerification(email)
      .then(() => {
        setPin("");
        setStopCountdown(60);
        const countdownInterval = setInterval(() => {
          setStopCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(countdownInterval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch((error) => {
        setStopCountdown(0);
        toast({
          title: error.response.data,
          position: "top",
          // description: "Please check email.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const doLogin = (res: AuthInfo) => {
    setLocalInfo(res);
    const userInfo = res.user;
    let organizationRoles: string[] = [];
    const organizations = userInfo.organizations;
    dispatch(setUserInfo(userInfo));
    if (!isEmpty(organizations)) {
      const organization = organizations.find(
        (item) => item.id === userInfo.currentOrganizationId
      );
      if (!!organization) {
        organizationRoles.push("Organization." + organization.organizationRole);
      }
    }
    dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));
  };

  const handlePinComplete = (value: string) => {
    setPin(value);
    setRegisterInfo((prevInfo) => ({
      ...(prevInfo || { email: "" }), // 保留之前的状态
      verificationCode: value, // 更新 username
    }));
  };

  const handlePinChange = (value: string) => {
    setPin(value);
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BgSignUp}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
          Sign Up!
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "1rem" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          {!showVerify ? (
            <>
              <Formik
                initialValues={{ email: "", password: "", confirmPassword: "" }}
                validationSchema={registerValidationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <Form>
                    <Field name="email">
                      {({ field, form }: FieldProps<{ email: string }>) => (
                        <FormControl
                          mb="24px"
                          isInvalid={
                            !!(form.errors.email && form.touched.email)
                          }
                        >
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            {...field}
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder="Your email address"
                            size="lg"
                            value={field.value.email}
                          />
                          <FormErrorMessage>
                            {form.errors.email as string}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password">
                      {({ field, form }: FieldProps<{ password: string }>) => (
                        <FormControl
                          mb="36px"
                          isInvalid={
                            !!(form.errors.password && form.touched.password)
                          }
                        >
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Password
                          </FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              borderRadius="15px"
                              fontSize="sm"
                              type={showPassword ? "text" : "password"}
                              placeholder="Your password"
                              size="lg"
                              value={field.value.password}
                            />
                            <InputRightElement
                              width="4.5rem"
                              height="100%"
                              cursor="pointer"
                            >
                              {showPassword ? (
                                <FiEye
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <FiEyeOff
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              )}
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.password as string}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="confirmPassword">
                      {({
                        field,
                        form,
                      }: FieldProps<{ confirmPassword: string }>) => (
                        <FormControl
                          mb="36px"
                          isInvalid={
                            !!(
                              form.errors.confirmPassword &&
                              form.touched.confirmPassword
                            )
                          }
                        >
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Password
                          </FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              borderRadius="15px"
                              fontSize="sm"
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm password"
                              size="lg"
                              value={field.value.confirmPassword}
                            />
                            <InputRightElement
                              width="4.5rem"
                              height="100%"
                              cursor="pointer"
                            >
                              {showConfirmPassword ? (
                                <FiEye
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                />
                              ) : (
                                <FiEyeOff
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                />
                              )}
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.confirmPassword as string}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      type="submit"
                      bg="teal.300"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      mt="20px"
                      isLoading={formikProps.isSubmitting}
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Form>
                )}
              </Formik>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Already have account?
                  <Link
                    color={titleColor}
                    as={RouterLink}
                    to={"/login"}
                    ms="5px"
                    href="#"
                    fontWeight="bold"
                  >
                    Login
                  </Link>
                </Text>
              </Flex>
              <Box position="relative" py="6">
                <Divider />
                <AbsoluteCenter bg="#fff" px="4">
                  OR
                </AbsoluteCenter>
              </Box>
              <Center py={6}>
                <Stack spacing={2} align={"center"} maxW={"md"} w={"full"}>
                  <Button
                    w={"full"}
                    variant={"outline"}
                    onClick={() => registerWithGoogle()}
                    leftIcon={<FcGoogle />}
                  >
                    <Center>
                      <Text>Sign up with Google</Text>
                    </Center>
                  </Button>
                </Stack>
              </Center>

              <Center p={6}>
                <Text>
                  By siging up to create an account I accept Company's
                  <Link
                    color={titleColor}
                    as={RouterLink}
                    to={"/privacy"}
                    ms="5px"
                    href="#"
                    fontWeight="bold"
                  >
                    Terms of Use and Privacy Policy
                  </Link>
                </Text>
              </Center>
            </>
          ) : (
            <>
              <Box>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="link"
                  onClick={() => setShowVerify(false)}
                  fontSize="14px"
                  pb={6}
                >
                  Back
                </Button>
                <Heading size="lg">Enter verification code</Heading>
                <Text fontSize="14px" py={2} color="gray">
                  A 6-digit code was sent to {registerInfo?.email}. Enter it
                  within 10 minutes
                </Text>
                <HStack py={4}>
                  <PinInput
                    onComplete={handlePinComplete}
                    onChange={handlePinChange}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="link"
                  onClick={() => sendEmail(registerInfo?.email!)}
                  isDisabled={stopCountdown > 0}
                >
                  {stopCountdown > 0
                    ? `Resend code (${stopCountdown}s)`
                    : "Resend code"}
                </Button>

                <Button
                  bg="teal.300"
                  color="white"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  mt="8rem"
                  width="full"
                  isDisabled={pin.length !== 6}
                  onClick={confirmVerify}
                >
                  Verify
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Register;
