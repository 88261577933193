import { AddToCartItem, CartItem, SubscribeCartItem } from "../models/cart";
import instance from "./interceptor";

export const addToMyCart = async (data: AddToCartItem[]) => {
    try {
      const response = await instance.post(`/api/Cart`, data);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  export const getCartList = async (): Promise<CartItem[]> => {
    try {
      const response = await instance.get(`/api/Cart`);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };
  
  export const deleteCartById = async (id: string) => {
    try {
      const response = await instance.delete(`/api/Cart/${id}`);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  export const subscribeCart = async (data: SubscribeCartItem[]) => {
    try {
      const response = await instance.post(`/api/Cart/subscribe`, data);
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };