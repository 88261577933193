import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  RadioGroup,
  Radio,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@ckeditor/ckeditor5-core";
import {
  ReleaseDIgitalWorkforceClientItem,
  ReleaseDIgitalWorkforceFunction,
  ReleaseDIgitalWorkforceRoleForm,
} from "../../../../../models/releaseDigitalWorkforce";
import { releaseDigitalWorkforceRoleValidationSchema } from "../../../../../utils/validation";
import { getReleaseDigitalWorkforceClientList, getReleaseDigitalWorkforceFunctionList } from "../../../../../services/releaseDigitalWorkforce";
import { Options } from "../../../../../models/common";
import { arrayToOptions } from "../../../../../utils/common";
import { organizationList } from "../../../../../services/organizationService";
import { OrganizationInfo } from "../../../../../models/auth";
import { domainList } from "../../../../../constants/constants";
import { FiCheck, FiFileText } from "react-icons/fi";
import { upsertDigitalWorkforce } from "../../../../../services/digitalWorkforceService";

interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: ReleaseDIgitalWorkforceRoleForm;
  onSubmit: () => void;
}

const EditReleaseDigitalWorkforceRole: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [selectedFunctions, setSelectedFunctions] = useState<Options[]>([]);
  const [functionList, setFunctionList] = useState<Options[]>([]);
  const [organizationsList, setOrganizationsList] = useState<Options[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Options>();
  const [selectedDomains, setSelectedDomains] = useState<Options[]>([]);
  const [editorData, setEditorData] = useState("");
  const [clientList, setClientList] = useState<Options[]>([]);
  const [selectedClient, setSelectedClient] = useState<Options>();
  const editorConstructor = ClassicEditor as unknown as {
    create(...args: any): Promise<Editor>;
  };
  useEffect(() => {
    initEditValues(initialValues);
    fetchFunctionList();
    fetchOrganizationList();
    fetchClientList();
  }, [initialValues]);

  const initEditValues = (values: ReleaseDIgitalWorkforceRoleForm) => {
    setSelectedFunctions(functionArrayToOptions(values.groups!));
    if (!!values.customizedOrganizationId) {
      setSelectedOrganization({
        label: values.customizedOrganizationName!,
        value: values.customizedOrganizationId,
      });
    }
    setSelectedDomains(arrayToOptions(initialValues.domains));
    setEditorData(initialValues.responsibilities || "");
  };

  const onReady = (editor: any) => {
    // 编辑器准备就绪后的回调
    console.log("Editor is ready to use!", editor);
  };

  const onError = (error: any) => {
    // 编辑器发生错误的回调
    console.error("Something went wrong:", error);
  };

  const fetchFunctionList = () => {
    getReleaseDigitalWorkforceFunctionList().then((res) => {
      setFunctionList(functionArrayToOptions(res));
    });
  };
  const fetchOrganizationList = () => {
    organizationList().then((res) => {
      setOrganizationsList(organizationArrayToOptions(res));
    });
  };
  const fetchClientList = () => {
    getReleaseDigitalWorkforceClientList().then((res) => {
      setClientList(clientArrayToOptions(res));
      const client = clientList.find(item => item.value === initialValues.clientId)
      setSelectedClient(client);
    });
  };

  const functionArrayToOptions = (arr: ReleaseDIgitalWorkforceFunction[]) => {
    if (isEmpty(arr)) {
      return [];
    } else {
      return arr.map((item) => ({ label: item.groupName, value: item.id! }));
    }
  };

  const organizationArrayToOptions = (arr: OrganizationInfo[]) => {
    if (isEmpty(arr)) {
      return [];
    } else {
      return arr.map((item) => ({ label: item.name, value: item.id! }));
    }
  };

  const clientArrayToOptions = (arr: ReleaseDIgitalWorkforceClientItem[]) => {
    if (isEmpty(arr)) {
      return [];
    } else {
      return arr.map((item) => ({ label: item.clientName, value: item.id! }));
    }
  };

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const editData: any = {
      name: values.name,
      responsibilities: values.responsibilities,
      type: Number(values.type),
      status: values.status,
    };
    let opt = "create";
    if (!isEmpty(values.id)) {
      editData.id = values.id;
      opt = "update";
    }
    //groupIds
    if (!isEmpty(selectedFunctions)) {
      editData.groupIds = selectedFunctions.map((option) => option.value);
    }
    //customizedOrganizationId
    if (editData.type === 2) {
      if (
        isEmpty(values.customizedOrganizationId) ||
        values.customizedOrganizationId ===
          "00000000-0000-0000-0000-000000000000"
      ) {
        formikHelpers.setSubmitting(false);
        toast({
          title: "Customized Organization is required.",
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        return;
      } else {
        editData.customizedOrganizationId = values.customizedOrganizationId;
      }
    }

    if(editData.type === 0) {
      editData.clientId = values.clientId;
    }

    //domains
    if (!isEmpty(selectedDomains)) {
      editData.domains = selectedDomains.map((option) => option.value);
    }
    upsertDigitalWorkforce(editData)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {!!initialValues.id
            ? t("release-digital-workforce.role.edit")
            : t("release-digital-workforce.role.create")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={releaseDigitalWorkforceRoleValidationSchema}
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Box my="0.5rem">
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel>
                          {t("release-digital-workforce.role.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="domains">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.domains && form.touched.domains}
                      >
                        <FormLabel>
                          {t("digital-workforce.list.domains")}:
                        </FormLabel>
                        <ChakraSelect
                          isMulti
                          colorScheme="purple"
                          options={arrayToOptions(domainList)}
                          value={selectedDomains}
                          onChange={(selectedOptions) => {
                            setSelectedDomains(selectedOptions as Options[]);
                            form.setFieldValue(field.name, selectedOptions);
                          }}
                        />
                        <FormErrorMessage>
                          {form.errors.domains}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="responsibilities">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.responsibilities &&
                          form.touched.responsibilities
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("digital-workforce.list.responsibilities")}:
                        </FormLabel>
                        <Box maxH={{ base: "12rem" }} overflow="auto">
                          <CKEditor
                            editor={editorConstructor}
                            data={editorData}
                            onReady={onReady}
                            onChange={(event: any, editor: any) => {
                              // 编辑器内容发生变化的回调
                              const data = editor.getData();
                              setEditorData(data);
                              form.setFieldValue(field.name, data);
                            }}
                            onError={onError}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "alignment",
                                "font",
                              ],
                              // language: 'zh-cn',
                            }}
                          />
                        </Box>
                        <FormErrorMessage>
                          {form.errors.responsibilities}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>

                <Box my="0.5rem">
                  <Field name="type">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.type && form.touched.type}
                      >
                        <HStack align="center">
                          <FormLabel>
                            {t("release-digital-workforce.role.type")}:
                          </FormLabel>
                          <RadioGroup
                            name="type"
                            {...field}
                            defaultValue="Open"
                            value={String(field.value)} // 将当前的值转换为字符串用于展示
                            onChange={(value) => {
                              form.setFieldValue(field.name, Number(value)); // 转换为 number 类型
                            }}
                          >
                            <Stack spacing={5} direction="row">
                              <Radio {...field} value="0">
                                {t("common.role-type.share")}
                              </Radio>
                              <Radio {...field} value="1">
                                {t("common.role-type.standard")}
                              </Radio>
                              <Radio {...field} value="2">
                                {t("common.role-type.customized")}
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </HStack>

                        <FormErrorMessage>
                          {form.errors.status}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {Number(props.values.type) === 2 && (
                  <Box my="0.5rem">
                    <Field name="customizedOrganizationId">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.customizedOrganizationId &&
                            form.touched.customizedOrganizationId
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("release-digital-workforce.role.organization")} :
                          </FormLabel>
                          <ChakraSelect
                            colorScheme="purple"
                            options={organizationsList}
                            value={selectedOrganization}
                            onChange={(selectedOption) => {
                              setSelectedOrganization(
                                selectedOption as Options
                              );
                              form.setFieldValue(
                                field.name,
                                selectedOption?.value
                              );
                              // onFunctionsChange(selectedOptions, field, form)
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.customizedOrganizationId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                )}
                <Box my="0.5rem">
                  <Field name="groupIds">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.groupIds && form.touched.groupIds
                        }
                      >
                        <FormLabel>
                          {t("release-digital-workforce.items.function")} :
                        </FormLabel>
                        <ChakraSelect
                          isMulti
                          colorScheme="purple"
                          options={functionList}
                          value={selectedFunctions}
                          onChange={(selectedOptions) => {
                            setSelectedFunctions(selectedOptions as Options[]);
                            form.setFieldValue(field.name, selectedOptions);
                          }}
                        />
                        <FormErrorMessage>
                          {form.errors.groupIds}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {Number(props.values.type) === 0 && (
                  <Box my="0.5rem">
                    <Field name="clientId">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.clientId &&
                            form.touched.clientId
                          }
                          isRequired
                        >
                          <FormLabel>
                            {t("release-digital-workforce.role.clientId")} :
                          </FormLabel>
                          <ChakraSelect
                            colorScheme="purple"
                            options={clientList}
                            value={selectedClient}
                            onChange={(selectedOption) => {
                              setSelectedClient(
                                selectedOption as Options
                              );
                              form.setFieldValue(
                                field.name,
                                selectedOption?.value
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.clientId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                )}
              </ModalBody>
              <ModalFooter>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{ md: "flex-end" }}
                >
                  <Button
                    leftIcon={<FiFileText />}
                    minW="8rem"
                    mr="1rem"
                    size="md"
                    colorScheme="gray"
                    isDisabled={isEmpty(editorData)}
                    isLoading={props.isSubmitting}
                    onClick={() => {
                      props.setFieldValue("status", 0);
                      props.submitForm();
                    }}
                  >
                    {t("common.btn.save-as-draft")}
                  </Button>
                  <Button
                    leftIcon={<FiCheck />}
                    minW="8rem"
                    size="md"
                    colorScheme="teal"
                    isDisabled={isEmpty(editorData)}
                    isLoading={props.isSubmitting}
                    onClick={() => {
                      props.setFieldValue("status", 1);
                      props.submitForm();
                    }}
                  >
                    {t("common.btn.publish")}
                  </Button>
                </Box>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditReleaseDigitalWorkforceRole;
