import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Center,
  Divider,
  AbsoluteCenter,
  HStack,
  PinInput,
  PinInputField,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldProps } from "formik";
import signInImage from "../../../assets/img/signInImage.jpg";
import {
  authValidationSchema,
  forgotValidationSchema,
} from "../../../utils/validation";
import {
  AuthForm,
  AuthInfo,
  GoogleUserInfo,
  OrganizationInfo,
} from "../../../models/auth";
import {
  fetchGoogleUserInfo,
  forgotPassword,
  login,
  sendForgotPasswordEmail,
  verifyForgotPasswordCode,
} from "../../../services/authService";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserRoles } from "../../../store";
import { useState } from "react";
import { isEmpty } from "lodash";
import { setDefaultOrganization } from "../../../services/organizationService";
import { setLocalInfo } from "../../../utils/auth";
import { useGoogleLogin } from "@react-oauth/google";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organizationList, setOrganizationList] = useState<OrganizationInfo[]>(
    []
  );
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [pin, setPin] = useState("");
  // 1 login  2 email  3 code 4 password confirm
  const [step, setStep] = useState<number>(1);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const [stopCountdown, setStopCountdown] = useState(0);
  const [showPassword, setShowPassword] = useState("");

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      fetchGoogleUserInfo(tokenResponse.access_token)
        .then((res: GoogleUserInfo) => {
          const info = {
            email: res.email,
            googleLogin: 1,
          } as AuthForm;
          _login(info);
        })
        .catch((error) => {
          console.error("Error fetching user information:", error);
        });
    },
    onError: (errorResponse) => console.log("Login Failed:", errorResponse),
  });

  const handleSubmit = (
    values: AuthForm,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    values.googleLogin = 0;
    _login(values);
    //
    formikHelpers.setSubmitting(false);
  };

  const _login = (info: AuthForm) => {
    login(info)
      .then((res: AuthInfo) => {
        doLogin(res);
        if (res.user.organizations.length > 1) {
          onOpen();
        } else {
          navigate("/workbench");
        }
      })
      .catch((error) => {
        toast({
          title: error.response.data.Message,
          position: "top",
          // description: "Please check email and password.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const doLogin = (res: AuthInfo) => {
    setLocalInfo(res);
    const userInfo = res.user;
    let organizationRoles: string[] = [];
    const organizations = userInfo.organizations;
    setOrganizationList(organizations);
    dispatch(setUserInfo(userInfo));

    if (!isEmpty(organizations)) {
      const organization = organizations.find(
        (item) => item.id === userInfo.currentOrganizationId
      );
      if (!!organization) {
        organizationRoles.push("Organization." + organization.organizationRole);
      }
    }
    dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));
  };

  const setDefaultOrganizationInfo = (id: string) => {
    setDefaultOrganization(id)
      .then((res) => {
        doLogin(res);
        navigate("/workbench");
      })
      .catch();
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleSendEmail = () => {
    //发送邮件，
    if (!email || !isEmailValid) return;
    setStep(3);
    handleSendForgotPasswordEmail();
  };

  const handleSendForgotPasswordEmail = () => {
    sendForgotPasswordEmail(email)
      .then(() => {
        setPin("");
        setStopCountdown(60);
        const countdownInterval = setInterval(() => {
          setStopCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(countdownInterval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch((error) => {
        setStopCountdown(0);
        toast({
          title: error.response.data,
          position: "top",
          // description: "Please check email.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handlePinComplete = (value: string) => {
    setPin(value);
  };

  const handlePinChange = (value: string) => {
    setPin(value);
  };

  const confirmVerify = () => {
    verifyForgotPasswordCode(email, pin)
      .then(() => {
        setStep(4);
      })
      .catch((error) => {
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleResetPassword = (
    values: { password: string; confirmPassword: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const data = {
      password: values.password,
      email: email,
    };
    forgotPassword(data)
      .then((res) => {
        toast({
          title: "Reset password successful.",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        formikHelpers.setSubmitting(false);
        setStep(1);
        setEmail("");
      })
      .catch((error) => {
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        w="100%"
        mx="auto"
        justifyContent="flex-end"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%" }}
        >
          <Flex
            direction="column"
            maxW="700px"
            width="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            {step === 1 && (
              <>
                <Heading color={titleColor} fontSize="32px" mb="10px">
                  Login
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColor}
                  fontWeight="bold"
                  fontSize="14px"
                >
                  Enter your email and password to sign in
                </Text>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={authValidationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formikProps) => (
                    <Form>
                      <Field name="email">
                        {({ field, form }: FieldProps<{ email: string }>) => (
                          <FormControl
                            mb="24px"
                            isInvalid={
                              !!(form.errors.email && form.touched.email)
                            }
                          >
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Email
                            </FormLabel>
                            <Input
                              {...field}
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="Your email address"
                              size="lg"
                              value={field.value.email}
                            />
                            <FormErrorMessage>
                              {form.errors.email as string}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password">
                        {({
                          field,
                          form,
                        }: FieldProps<{ password: string }>) => (
                          <FormControl
                            mb="36px"
                            isInvalid={
                              !!(form.errors.password && form.touched.password)
                            }
                          >
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Password
                            </FormLabel>
                            <Input
                              {...field}
                              borderRadius="15px"
                              fontSize="sm"
                              type="password"
                              placeholder="Your password"
                              size="lg"
                              value={field.value.password}
                            />
                            <FormErrorMessage>
                              {form.errors.password as string}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <FormControl
                        display="flex"
                        width="full"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Flex>
                          <Switch
                            id="remember-login"
                            colorScheme="teal"
                            me="10px"
                          />
                          <FormLabel
                            htmlFor="remember-login"
                            mb="0"
                            ms="1"
                            fontWeight="normal"
                          >
                            Remember me
                          </FormLabel>
                        </Flex>

                        <Text
                          color={titleColor}
                          cursor="pointer"
                          // onClick={onEmailOpen}
                          onClick={() => setStep(2)}
                        >
                          Forgot Password?
                        </Text>
                      </FormControl>
                      <Button
                        type="submit"
                        bg="teal.300"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        isLoading={formikProps.isSubmitting}
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}
                      >
                        Login
                      </Button>
                    </Form>
                  )}
                </Formik>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  maxW="100%"
                  mt="0px"
                >
                  <Text color={textColor} fontWeight="medium">
                    Don't have an account?
                    <Link
                      color={titleColor}
                      as={RouterLink}
                      to={"/register"}
                      ms="6px"
                      mr="6px"
                      fontWeight="bold"
                    >
                      Sign Up
                    </Link>
                  </Text>
                </Flex>
                <Box position="relative" py={6}>
                  <Divider />
                  <AbsoluteCenter bg="#EDF2F7" px="4">
                    OR
                  </AbsoluteCenter>
                </Box>
                <Center py={6}>
                  <Stack spacing={2} align={"center"} maxW={"md"} w={"full"}>
                    <Button
                      w={"full"}
                      variant={"outline"}
                      onClick={() => loginWithGoogle()}
                      leftIcon={<FcGoogle />}
                    >
                      <Center>
                        <Text>Sign in with Google</Text>
                      </Center>
                    </Button>
                  </Stack>
                </Center>
                <Center p={6}>
                  <Text>
                    By siging up to create an account I accept Company's
                    <Link
                      color={titleColor}
                      as={RouterLink}
                      to={"/privacy"}
                      ms="5px"
                      href="#"
                      fontWeight="bold"
                    >
                      Terms of Use and Privacy Policy
                    </Link>
                  </Text>
                </Center>
              </>
            )}
            {step === 2 && (
              <>
                <Box>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    variant="link"
                    onClick={() => setStep(1)}
                    fontSize="14px"
                    pb={6}
                  >
                    Back
                  </Button>
                  <FormControl isInvalid={!isEmailValid && email !== ""}>
                    <Text mb={4}>
                      Please enter your registered email address to receive the
                      link to reset your password.
                    </Text>

                    <Input
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      isDisabled={stopCountdown > 0}
                    />
                    {!isEmailValid && email !== "" && (
                      <FormErrorMessage>Invalid email address</FormErrorMessage>
                    )}
                  </FormControl>
                  <Button
                    mt="8rem"
                    size="md"
                    bg="teal.300"
                    w="100%"
                    h="45"
                    mb="20px"
                    color="white"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    onClick={handleSendEmail}
                    isDisabled={
                      stopCountdown > 0 || !isEmailValid || email === ""
                    }
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
            {step === 3 && (
              <>
                <Box>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    variant="link"
                    onClick={() => setStep(2)}
                    fontSize="14px"
                    pb={6}
                  >
                    Back
                  </Button>
                  <Heading size="lg">Enter verification code</Heading>
                  <Text fontSize="14px" py={2} color="gray">
                    A 6-digit code was sent to {email}. Enter it within 10
                    minutes
                  </Text>
                  <HStack py={4}>
                    <PinInput
                      onComplete={handlePinComplete}
                      onChange={handlePinChange}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                  <Button
                    size="sm"
                    colorScheme="teal"
                    variant="link"
                    onClick={() => handleSendForgotPasswordEmail}
                    isDisabled={stopCountdown > 0}
                  >
                    {stopCountdown > 0
                      ? `Resend code (${stopCountdown}s)`
                      : "Resend code"}
                  </Button>

                  <Button
                    bg="teal.300"
                    color="white"
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}
                    mt="8rem"
                    width="full"
                    isDisabled={pin.length !== 6}
                    onClick={confirmVerify}
                  >
                    Verify
                  </Button>
                </Box>
              </>
            )}

            {step === 4 && (
              <Box>
                <Button
                  size="sm"
                  colorScheme="teal"
                  variant="link"
                  onClick={() => setStep(3)}
                  fontSize="14px"
                  pb={6}
                >
                  Back
                </Button>
                <Heading size="lg" mb="1rem">
                  Reset Password
                </Heading>

                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={forgotValidationSchema}
                  onSubmit={handleResetPassword}
                >
                  {(formikProps) => (
                    <Form>
                      <Field name="password">
                        {({
                          field,
                          form,
                        }: FieldProps<{ password: string }>) => (
                          <FormControl
                            mb="36px"
                            isInvalid={
                              !!(form.errors.password && form.touched.password)
                            }
                          >
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              New Password
                            </FormLabel>
                            <InputGroup>
                              <Input
                                {...field}
                                borderRadius="15px"
                                fontSize="sm"
                                type={
                                  showPassword === "new" ? "text" : "password"
                                }
                                placeholder="Your new password"
                                size="lg"
                                value={field.value.password}
                              />
                              <InputRightElement
                                width="4.5rem"
                                height="100%"
                                cursor="pointer"
                              >
                                {showPassword === "new" ? (
                                  <FiEye onClick={() => setShowPassword("")} />
                                ) : (
                                  <FiEyeOff
                                    onClick={() => setShowPassword("new")}
                                  />
                                )}
                              </InputRightElement>
                            </InputGroup>

                            <FormErrorMessage>
                              {form.errors.password as string}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="confirmPassword">
                        {({
                          field,
                          form,
                        }: FieldProps<{ confirmPassword: string }>) => (
                          <FormControl
                            mb="36px"
                            isInvalid={
                              !!(
                                form.errors.confirmPassword &&
                                form.touched.confirmPassword
                              )
                            }
                          >
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Confirm Password
                            </FormLabel>

                            <InputGroup>
                              <Input
                                {...field}
                                borderRadius="15px"
                                fontSize="sm"
                                type={
                                  showPassword === "confirm"
                                    ? "text"
                                    : "password"
                                }
                                placeholder="Confirm Password"
                                size="lg"
                                value={field.value.confirmPassword}
                              />

                              <InputRightElement
                                width="4.5rem"
                                height="100%"
                                cursor="pointer"
                              >
                                {showPassword === "confirm" ? (
                                  <FiEye onClick={() => setShowPassword("")} />
                                ) : (
                                  <FiEyeOff
                                    onClick={() => setShowPassword("confirm")}
                                  />
                                )}
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.confirmPassword as string}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Button
                        type="submit"
                        bg="teal.300"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        isLoading={formikProps.isSubmitting}
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}
                      >
                        Confirm
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
          </Flex>
        </Flex>
        <Box display={{ base: "none", md: "block" }} h="100%" w="40%">
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgPosition="50%"
            bgSize="cover"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please select a default organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {organizationList.map((item, index) => (
              <Text
                key={index}
                fontSize="xl"
                py="1rem"
                my="0.5rem"
                bgColor="teal.50"
                textAlign="center"
                borderRadius="2px"
                _hover={{
                  bg: "teal.200",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => setDefaultOrganizationInfo(item.id)}
              >
                {item.name}
              </Text>
            ))}
            <ModalFooter></ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Login;
