import { RequirementQuery } from "../models/requirement";
import instance from "./interceptor";

export const getRequirementsList = async (data?: RequirementQuery) => {
  try {
    const response = await instance.get(`/api/requirements`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirements = async (data: any) => {
  try {
    const response = await instance.post(`/api/requirements`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getRequirementsById = async (id: string) => {
  try {
    const response = await instance.get(`/api/requirements/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirementsApplication = async (data: any) => {
  try {
    const response = await instance.post(`/api/requirements/application`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getApplicationList = async (requirementId: string) => {
  try {
    const response = await instance.get(
      `/api/requirements/${requirementId}/applications`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getApplicationDetail = async (applicationId: string) => {
  try {
    const response = await instance.get(
      `/api/requirements/applications/${applicationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateApplicationStatus = async (applicationId: string) => {
  try {
    const response = await instance.put(
      `/api/requirements/applications/${applicationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createRequirementTimeLine = async (data: any) => {
  try {
    const response = await instance.post(
      `/api/requirements/tasktimeline`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getRequirementTimeLine = async (id: string) => {
  try {
    const response = await instance.get(`/api/requirements/tasktimeline/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteRequirement = async (id: string) => {
  try {
    const response = await instance.delete(`/api/requirements/draft/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateRequirementStatus = async (
  requirementId: string,
  status: number
) => {
  try {
    const response = await instance.put(
      `/api/requirements/status/${requirementId}?status=${status}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
