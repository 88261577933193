import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { editApplyValidationSchema } from "../../../../utils/validation";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@ckeditor/ckeditor5-core";
import { ApplicationForm } from "../../../../models/requirement";
import { createRequirementsApplication } from "../../../../services/requirementService";

interface EditOrAddFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: ApplicationForm;
  onRefresh: () => void;
}

const EditApply: React.FC<EditOrAddFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  initialValues,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState("");
  const toast = useToast();

  const editorConstructor = ClassicEditor as unknown as {
    create(...args: any): Promise<Editor>;
  };

  useEffect(() => {
    initEditValues();
  }, [initialValues]);

  const initEditValues = () => {
    setEditorData(initialValues.taskDescription || "");
  };

  const onReady = (editor: any) => {
    // 编辑器准备就绪后的回调
    console.log("Editor is ready to use!", editor);
  };

  const onError = (error: any) => {
    // 编辑器发生错误的回调
    console.error("Something went wrong:", error);
  };

  const handleSubmit = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let editData: any = {
      requirementId: values?.requirementId,
      estimatedTime: values?.estimatedTime || 0,
      quotation: values?.quotation || 0,
      taskDescription: values?.taskDescription || "",
    };
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
      editData.id = values.id;
    }
    createRequirementsApplication(editData)
      .then((res) => {
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
        onClose();
        onRefresh();
      })
      .catch((err) => {
        toast({
          title: t("common.btn." + opt + "-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>{t("requirements.apply.title")}</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          validationSchema={editApplyValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Box my="0.5rem">
                  <Field name="estimatedTime">
                    {({ field, form, ...props }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.estimatedTime &&
                          form.touched.estimatedTime
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("requirements.list.estimate")}:
                        </FormLabel>
                        <NumberInput
                          {...field}
                          {...props}
                          // value={field.value.estimatedTime}
                          name="estimatedTime"
                          min={1}
                          max={3000}
                          onChange={(val: number) =>
                            form.setFieldValue(field.name, val)
                          }
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>
                          {form.errors.estimatedTime}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="quotation">
                    {({ field, form, ...props }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.quotation && form.touched.quotation
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("requirements.list.quotation")}:
                        </FormLabel>
                        <NumberInput
                          {...field}
                          {...props}
                          // value={field.value.estimatedTime}
                          name="quotation"
                          min={1}
                          max={10000}
                          onChange={(val: number) =>
                            form.setFieldValue(field.name, val)
                          }
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>
                          {form.errors.estimatedTime}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <FormControl mb={4}>
                  <Field name="taskDescription">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.taskDescription &&
                          form.touched.taskDescription
                        }
                      >
                        <FormLabel>
                          {t("requirements.apply.task-desc")} :
                        </FormLabel>
                        <Box
                          maxH={{ base: "15rem", md: "13rem" }}
                          overflow="auto"
                        >
                          <CKEditor
                            editor={editorConstructor}
                            data={editorData}
                            onReady={onReady}
                            onChange={(event: any, editor: any) => {
                              // 编辑器内容发生变化的回调
                              const data = editor.getData();
                              setEditorData(data);
                              form.setFieldValue(field.name, data);
                            }}
                            onError={onError}
                            config={{
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "alignment",
                                "font",
                              ],
                              // language: 'zh-cn',
                            }}
                          />
                        </Box>
                      </FormControl>
                    )}
                  </Field>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditApply;
