import axios from "axios";
import { AuthForm } from "../models/auth";
import {
  EmailDetail,
  ForgotPasswordForm,
  ProfileForm,
  ResetPasswordForm,
} from "../models/user";
import instance from "./interceptor";

export const login = async (data: AuthForm) => {
  try {
    const response = await instance.post("/api/user/login", data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const register = async (data: AuthForm) => {
  try {
    const response = await instance.post("/api/user/register", data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const bind = async (data: AuthForm) => {
  try {
    const response = await instance.post("/api/user/bind", data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const active = async (userId: string) => {
  try {
    const response = await instance.put(`/api/user/activate/${userId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userList = async () => {
  try {
    const response = await instance.get(`/api/user`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userParameter = async () => {
  try {
    const response = await instance.get(`/api/user/parameter`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userInitial = async () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")!);
  try {
    const response = await instance.get(`/api/user/${userInfo.id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const guestLogin = async () => {
  try {
    const response = await instance.post(`/api/user/AccessAsGuest`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const userInfo = async (userId: string) => {
  try {
    const response = await instance.get(`/api/profiles/${userId}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateUserInfo = async (data: ProfileForm) => {
  try {
    const response = await instance.post(`/api/profiles`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const resetPassword = async (data: ResetPasswordForm) => {
  try {
    const response = await instance.post(`/api/user/ChangePassword`, data);
    return response.data;
  } catch (error) {
    // console.error("error:", error);
    throw error;
  }
};

export const sendEmail = async (email: string) => {
  try {
    const response = await instance.post(
      `/api/user/SendForgotPasswordEmail/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const sendForgotPasswordEmail = async (email: string) => {
  try {
    const response = await instance.post(`/api/user/ForgotPassword/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const verifyForgotPasswordCode = async (email: string, code: string) => {
  try {
    const response = await instance.post(
      `/api/User/ForgotPassword/Verify/${email}/${code}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const forgotPassword = async (data: ForgotPasswordForm) => {
  try {
    const response = await instance.post(`/api/user/forgotPassword`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGoogleUserInfo = async (access_token: string) => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const registrationVerification = async (email: string) => {
  try {
    const response = await instance.post(
      `/api/User/RegistrationVerification/${email}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updatePrimaryEmail = async (data: EmailDetail) => {
  try {
    const response = await instance.post(`/api/Profiles/SetEmail`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const removeEmail = async (email: string) => {
  try {
    const response = await instance.delete(`/api/Profiles/Email/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const verificationEmail = async (email: string) => {
  try {
    const response = await instance.post(`/api/Profiles/Verification/${email}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const bindEmail = async (email: string, verificationCode: string) => {
  try {
    const response = await instance.post(
      `/api/Profiles/BindEmail/${email}/${verificationCode}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
