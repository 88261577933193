import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Empty = () => {
  const { t } = useTranslation();
  return (
    <Box bgColor="#fff" p={4} mt="1rem">
      <Text fontSize="lg" color="gray.500">
        {t("empty.title")}
      </Text>
    </Box>
  );
};

export default Empty;
