import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TaskTimeline } from "../../../../models/requirement";
interface TaskTimelineProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  taskTimelineInfo: TaskTimeline;
}

const TaskTimelineDetails: React.FC<TaskTimelineProps> = (props) => {
  const { isOpen, onClose, taskTimelineInfo } = props;
  const { t } = useTranslation();

  return (
    <>
      {!!taskTimelineInfo && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("requirements.taskTimeline.title")}</ModalHeader>
            <ModalCloseButton />
            <Box bg={"#fff"} p="2rem">
              {[
                "estimate",
                "design",
                "development",
                "codeReview",
                "integrationTest",
                "inspect",
                "settlement",
              ].map((name) => (
                <Flex mb={2} alignItems="center" key={name}>
                  <Text fontWeight="700" size="sm" color="gray.400">
                    {t(`requirements.taskTimeline.${name}`)}:
                  </Text>
                  <Text size="sm" pl="1rem">
                    {(taskTimelineInfo as Record<string, any>)[name]}
                  </Text>
                </Flex>
              ))}
            </Box>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TaskTimelineDetails;
