import React, { useEffect } from "react";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  List,
  ListItem,
  ListIcon,
  Collapse,
  Image,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FiChevronsLeft } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toggleHide } from "../../../store";
import routes from "../../../config/routes";
import { SidebarProps } from "../../../models/layout";
import yuanLiteImage from "../../../assets/img/YuanLite.png";
import "./index.css";
import { hasPermission } from "../../../utils/auth";

const Sidebar: React.FC<SidebarProps> = ({ onClose, ...rest }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState("-");
  const [selectedFullPath, setSelectedFullPath] = React.useState("-");
  const isHideMenu = useSelector((state: any) => state.isHideMenu);
  const userRoles = useSelector((state: any) => state.userRoles);
  const dispatch = useDispatch();

  const handleItemClick = (menuName: string, index: any) => {
    setOpen((prevIndex) => (prevIndex === index ? null : index));
    setSelectedMenu(menuName);
    setSelectedFullPath(menuName);
  };

  const handleSubItemClick = (subMenuName: string) => {
    setSelectedMenu(subMenuName);
    setSelectedFullPath(subMenuName);
  };

  const filterMenu = (path: string) => {
    routes.forEach((route, index: any) => {
      if (route.path === path) {
        setOpen(index);
        return;
      }
      if (route?.children && route?.children.length > 0) {
        route.children.forEach((subRoute, suIndex: any) => {
          if (subRoute.path === path) {
            setOpen(index);
            return;
          }
        });
      }
    });
  };

  useEffect(() => {
    //为了二级域名下面的隐藏菜单在左侧被大类选中, 切割地一个path
    let pathname = location.pathname;
    setSelectedFullPath(pathname);
    if (pathname.split("/").length > 2) {
      const firstPart = pathname.split("/")[1];
      pathname = `/${firstPart}`;
    }
    setSelectedMenu(pathname);
    filterMenu(pathname);
  }, [location.pathname]);

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: isHideMenu ? 20 : 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        pl={{ base: 4, md: 0 }}
        mx={{ base: 0, md: isHideMenu ? 1 : 8 }}
        justifyContent={{ base: "space-between", md: "center" }}
      >
        <Image src={yuanLiteImage} maxH="60px"></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <List spacing={3}>
        {routes.map(
          (route, index) =>
            !route.isHide &&
            hasPermission(userRoles, route?.requiredRoles) && (
              <Box _focus={{ boxShadow: "none" }} key={index}>
                <ListItem
                  pl="4"
                  pr="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: "cyan.400",
                    color: "white",
                  }}
                  // display="flex"
                  // width="100%"
                  className="menu-item"
                  bg={selectedMenu === route.path ? "cyan.400" : "inherit"}
                  color={selectedMenu === route.path ? "white" : "inherit"}
                  key={route.name}
                >
                  <Link
                    h="40px"
                    width="100%"
                    as={RouterLink}
                    to={route.children ? undefined : route.path}
                    display="flex"
                    alignItems="center"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      handleItemClick(route.path, index);
                      if (!route.children) {
                        onClose();
                      }
                    }}
                  >
                    <ListIcon as={route.icon} />
                    {!isHideMenu && <> {t(route.lngStr)}</>}
                    {route.children && (
                      <Box ml="auto">
                        <Icon
                          as={open === index ? MdExpandLess : MdExpandMore}
                        />
                      </Box>
                    )}
                  </Link>
                </ListItem>
                {route.children && (
                  <Collapse in={open === index} animateOpacity>
                    <List spacing={3}>
                      {route?.children?.map(
                        (subRoute, subIndex) =>
                          !subRoute.isHide &&
                          hasPermission(userRoles, subRoute?.requiredRoles) && (
                            <Box
                              _focus={{ boxShadow: "none" }}
                              key={index + subRoute.path}
                            >
                              <ListItem
                                h="40px"
                                pl="8"
                                pt="2"
                                pr="4"
                                mx="4"
                                mt="1"
                                borderRadius="lg"
                                role="group"
                                cursor="pointer"
                                _hover={{
                                  bg: "pink.50",
                                }}
                                bg={
                                  selectedFullPath === subRoute.path
                                    ? "pink.50"
                                    : "inherit"
                                }
                                color={
                                  selectedFullPath === subRoute.path
                                    ? "pink.400"
                                    : "inherit"
                                }
                                key={subRoute.name}
                              >
                                <Link
                                  style={{ textDecoration: "none" }}
                                  as={RouterLink}
                                  to={subRoute.path}
                                  display="flex"
                                  alignItems="center"
                                  onClick={() => {
                                    handleSubItemClick(subRoute.path);
                                    onClose();
                                  }}
                                >
                                  {subRoute.icon ? (
                                    <ListIcon as={subRoute.icon} />
                                  ) : (
                                    <div style={{ width: "2em" }}></div>
                                  )}
                                  {!isHideMenu && <> {t(subRoute.lngStr)}</>}
                                </Link>
                              </ListItem>
                            </Box>
                          )
                      )}
                    </List>
                  </Collapse>
                )}
              </Box>
            )
        )}
      </List>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        bg={useColorModeValue("white", "gray.900")}
        borderTopWidth="1px"
        p={2}
        cursor="pointer"
        display={{ base: "none", md: "block" }}
        onClick={() => dispatch(toggleHide())}
      >
        <Flex justifyContent="center">
          <Icon
            as={FiChevronsLeft}
            fontSize="2xl"
            cursor="pointer"
            transform={isHideMenu ? "rotate(180deg)" : "rotate(0)"}
            transition="transform 0.3s"
          />
        </Flex>
      </Box>
    </Box>
  );
};
export default Sidebar;
