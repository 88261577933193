import { Case } from "../models/case";

export const parseCaseList = (res: Case[]) => {
  return res.map(parseSingleCase);
};

export const parseSingleCase = (item: Case) => {
  const newItem = { ...item };
  if (typeof newItem.tags === "string" && newItem.tags !== "") {
    newItem.tags = JSON.parse(newItem.tags);
  }
  if (typeof newItem.categories === "string" && newItem.categories !== "") {
    newItem.categories = JSON.parse(newItem.categories);
  }
  if (!!newItem.link) {
    newItem.link = "";
  }
  if (!!newItem?.startDate && newItem?.startDate?.length > 10) {
    newItem.startDate = undefined;
  }
  if (!!newItem?.endDate && newItem?.endDate?.length > 10) {
    newItem.endDate = undefined;
  }
  if (newItem.images?.length > 0) {
    newItem.carouselImages = newItem.images.map((item) => ({ url: item.url }));
  }
  return newItem;
};
