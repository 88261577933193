import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Td,
  Tr,
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  useColorModeValue,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationInfo, TaskTimeline } from "../../../models/requirement";
import {
  getApplicationList,
  getRequirementTimeLine,
  updateApplicationStatus,
} from "../../../services/requirementService";
import TaskTimelineDetails from "../TaskTimeline/Details";
import { canEdit } from "../../../utils/auth";
import React from "react";

interface ApplicationProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRefresh: () => void;
  requirementId: string;
}

const ApplicationList: React.FC<ApplicationProps> = ({
  isOpen,
  onOpen,
  onClose,
  onRefresh,
  requirementId,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const [tableList, setTableList] = useState<ApplicationInfo[]>([]);
  const [taskTimelineInfo, setTaskTimelineInfo] = useState<TaskTimeline | null>(
    null
  );
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getApplicationList(requirementId).then((res) => {
      setTableList(res);
    });
  };

  const tableFiled = [
    t("organization.list.name"),
    t("organization.list.number"),
    t("requirements.apply.applied-date"),
    t("requirements.list.estimate"),
    t("requirements.list.quotation"),
    t("requirements.list.status"),
    t("common.btn.actions"),
  ];

  const handleApproveApplication = (id: string) => {
    updateApplicationStatus(id).then(() => {
      onRefresh();
      fetchData();
    });
  };

  const handleView = (taskTimelineId: string) => {
    getRequirementTimeLine(taskTimelineId).then((res) => {
      setTaskTimelineInfo(res);
      onViewOpen();
    });
  };

  const _renderCell = (
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td py="0.5rem" {...extraProps}>
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const _renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return "gray";
      case 1:
        return "green";
      default:
        return "red";
    }
  };

  const _renderTableRow = (row: ApplicationInfo, index: number) => {
    return (
      <Tr key={index}>
        {_renderCell(row.applierOrganizationName, "sm", "bold")}
        {_renderCell(row.applierOrganizationNumber, "sm", "normal")}
        {_renderCell(row?.appliedDate.replace("T", " "), "sm", "normal")}
        {_renderCell(row.estimatedTime, "sm", "normal")}
        {_renderCell(row.quotation, "sm", "normal")}
        {_renderCell(
          t("requirements.apply.status-" + row.status),
          "sm",
          "normal",
          { color: _renderStatus(row.status) }
        )}
        <Td py="0.5rem" display="flex" minH="40px">
          {row.status === 0 && (
            <Button
              size="sm"
              isDisabled={!canEdit()}
              colorScheme="teal"
              onClick={() => handleApproveApplication(row.id)}
            >
              {t("common.btn.approve")}
            </Button>
          )}
          {row.status === 1 && (
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => handleView(row.taskTimelineId!)}
            >
              {t("requirements.taskTimeline.title")}
            </Button>
          )}
        </Td>
        {isViewOpen && taskTimelineInfo && (
          <TaskTimelineDetails
            isOpen={isViewOpen}
            onOpen={onViewOpen}
            onClose={onViewClose}
            taskTimelineInfo={taskTimelineInfo!}
          ></TaskTimelineDetails>
        )}
      </Tr>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "75rem", lg: "80rem" }}>
        <ModalHeader>{t("requirements.apply.application-title")}</ModalHeader>
        <ModalCloseButton />
        <Box
          p={{ base: "1rem", md: "2rem" }}
          overflowX={{ base: "scroll", md: "hidden" }}
        >
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {tableFiled.map((caption, idx) => (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tableList.map((row: ApplicationInfo, index) => (
                <React.Fragment key={index}>
                  {_renderTableRow(row, index)}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ApplicationList;
