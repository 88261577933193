import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Heading,
  Flex,
  Link,
  Image,
  useColorModeValue,
  Button,
  Avatar,
  HStack,
  Stack,
} from "@chakra-ui/react";
import TurnKeyServiceImg from "../../assets/img/TurnKeyService.png";
import { getDigitalWorkforceList } from "../../services/digitalWorkforceService";
import { DigitalWorkforceRow } from "../../models/digitalWorkforce";
import { useTranslation } from "react-i18next";
import Marquee from "../../components/Marquee";
import { messageList } from "../../services/messageService";
import RequirementCard from "../Requirements/Card";
import { Requirement } from "../../models/requirement";
import { getRequirementsList } from "../../services/requirementService";
import { getCaseList } from "../../services/caseService";
import { isEmpty } from "lodash";
import { parseCaseList } from "../../utils/case";
import { Case } from "../../models/case";
import { Broadcast } from "../../models/dashboard";
import CaseCard from "../CaseManagement/Card";
import { _renderDigitalWorkforceType } from "../../utils/digitalWorkforce";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const [msgList, setMsgList] = useState<string[]>([]);
  const [requirementList, setRequirementList] = useState<Requirement[]>([]);
  const [caseList, setCaseList] = useState<Case[]>([]);
  const [list, setList] = useState<DigitalWorkforceRow[]>([]);
  const responsiveSpacing = { base: "1rem", md: "2rem" };

  useEffect(() => {
    getDigitalWorkforceList().then((res) => {
      setList(res.slice(0, 4));
    });
    messageList().then((res) => {
      initMsgData(res);
    });
    getRequirementsList({ title: "" }).then((res) => {
      setRequirementList(res.slice(0, 4));
    });
    getCaseList("")
      .then((res) => {
        if (!isEmpty(res)) {
          setCaseList(parseCaseList(res.slice(0, 4)));
        }
      })
      .catch();
  }, []);

  const initMsgData = (data: Broadcast) => {
    const msgData = data?.messages.map(
      (item) => `${item.userName}: ${item.messageInfo}`
    );
    if (!!msgData && msgData.length > 0) {
      msgData.unshift("[Linkedin]");
    }

    const requirementData = data?.requirements.map(
      (item) => `${item.publisherOrganizationName}: ${item.title}`
    );

    if (!!requirementData && requirementData.length > 0) {
      requirementData.unshift("[Project Marketplace]");
    }

    const rpaTaskData = data?.rpaTasks.map(
      (item) => `${item.organizationName}: ${item.digitalWorkforceName}`
    );

    if (!!rpaTaskData && rpaTaskData.length > 0) {
      rpaTaskData.unshift("[RPA Task]");
    }
    const allData = msgData.concat(requirementData).concat(rpaTaskData);

    setMsgList(allData);
  };

  const refreshData = () => {};

  return (
    <Box>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(-225deg, #2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%)",
        }}
      >
        <Box display="flex" minH={{ base: "300px", md: "500px" }}>
          <Box
            minH={{ base: "300px", md: "500px" }}
            w={{ base: "100%", md: "80%" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading as="h2" size="2xl" color="white" p="1rem">
              {t("home.label.title")}
            </Heading>
            <Text color="white" fontSize="2xl" p="1rem">
              {t("home.label.sub-title")}
            </Text>
          </Box>
          <Box
            w={{ base: "0%", md: "20%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              w="80%"
              src={TurnKeyServiceImg}
              borderRadius={responsiveSpacing}
              overflow="hidden"
            ></Image>
          </Box>
        </Box>
        <Box maxW="1240px" margin="0 auto">
          <Marquee msgs={msgList}></Marquee>
        </Box>
      </Box>
      <Box>
        <Box bg={useColorModeValue("gray.100", "gray.900")} px="1rem">
          <Box maxW="1240px" margin="0 auto" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box py={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="purple"
                  textAlign="center"
                  w="full"
                >
                  {t("menu.digital-workforce")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.digital-workforces")}
                </Text>
              </Box>

              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {list.map((row, index) => {
                  return (
                    <Box
                      key={index}
                      bg={"#fff"}
                      borderRadius="5px"
                      _hover={{
                        boxShadow: "lg",
                        rounded: "md",
                        cursor: "pointer",
                      }}
                    >
                      <Box w="100%" bg="gray.200" pos="relative" pb={4}>
                        <Flex justify="flex-end" fontSize="0.8rem">
                          <Text pr={2} pt={1}>
                            {t(_renderDigitalWorkforceType(row.type))}
                          </Text>
                        </Flex>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Avatar
                            size="xl"
                            name={row.name}
                            bg="teal.500"
                            color="white"
                          />
                        </Box>
                      </Box>
                      <Box px="0.8rem" pb="0.8rem">
                        <Heading
                          textAlign="center"
                          fontSize={"large"}
                          mt={2}
                          mb="0.5rem"
                          h="2.4em"
                          noOfLines={2}
                        >
                          {row.name}
                        </Heading>

                        {/* Statistics */}
                        <HStack justify="center">
                          <Flex
                            flexDirection="column"
                            alignItems="center"
                            w="50%"
                          >
                            <Text
                              fontSize="2xl"
                              fontWeight="bold"
                              color="blue.500"
                            >
                              {row.executionCount}
                            </Text>
                            <Text fontSize="12px" color="gray">
                              {t("digital-workforce.list.number-of-executions")}
                            </Text>
                          </Flex>
                          <Flex
                            flexDirection="column"
                            alignItems="center"
                            w="50%"
                          >
                            <Text
                              fontSize="2xl"
                              fontWeight="bold"
                              color="blue.500"
                            >
                              {row.totalExecutionTime}h
                            </Text>
                            <Text fontSize="12px" color="gray">
                              {t("digital-workforce.list.total-execution-time")}
                            </Text>
                          </Flex>
                        </HStack>

                        <Text mt={2} textAlign="center">
                          {t("digital-workforce.list.subscribed-org")}
                        </Text>

                        <Stack
                          direction="row"
                          spacing={-10}
                          justify="center"
                          mt={1}
                        >
                          {isEmpty(row?.subscribedOrganizations) ? (
                            <Avatar size="md" />
                          ) : (
                            <>
                              {row?.subscribedOrganizations
                                ?.slice(0, 5)
                                .map((organization) => {
                                  return (
                                    <Avatar size="md" name={organization} />
                                  );
                                })}
                            </>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/digital-workforce">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box>
        <Box bg="#fff" px="1rem">
          <Box maxW="1240px" margin="0 auto" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box py={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="cyan.400"
                  textAlign="center"
                >
                  {t("menu.project-marketplace")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.project-marketplace")}
                </Text>
              </Box>
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {requirementList.map((item, index) => (
                  <RequirementCard
                    key={index}
                    requirementInfo={item}
                    isEdit={false}
                    onRefresh={refreshData}
                  ></RequirementCard>
                ))}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/project-marketplace">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box>
        <Box bg={useColorModeValue("gray.100", "gray.900")} px="1rem">
          <Box maxW="1240px" margin="0 auto" pt="1rem" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box mb={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="teal"
                  textAlign="center"
                >
                  {t("case.list.case")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.case")}
                </Text>
              </Box>
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {caseList.map((item, index) => {
                  return <CaseCard key={index} caseInfo={item} />;
                })}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/articles/cases">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
