import { BlogQuery } from "../models/blog";
import instance from "./interceptor";

export const getDispatchTasksList = async () => {
  try {
    const response = await instance.get(`/api/usertasks`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDispatchTaskDetail = async (id: string) => {
  try {
    const response = await instance.get(`/api/usertasks/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateDispatchTask = async (data: any) => {
  try {
    const response = await instance.post(`/api/usertasks`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteDispatchTask = async (id: string) => {
  try {
    const response = await instance.delete(`/api/usertasks/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDispatchTasksGroups = async () => {
  try {
    const response = await instance.get(`/api/usertasks/groups`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const toggleDispatchTaskEnable = async (id: string, enable: boolean) => {
  try {
    const response = await instance.post(`/api/usertasks/${id}/${enable}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const executeDispatchTask = async (id: string) => {
  try {
    const response = await instance.post(`/api/usertasks/executetask/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const stopDispatchTask = async (id: string) => {
  try {
    const response = await instance.post(`/api/usertasks/stoptask/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};