import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
  InputRightElement,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { FiEye, FiEyeOff, FiXCircle } from "react-icons/fi";
import BgSignUp from "../../../assets/img/BgSignUp.png";
import { Formik, Form, Field, FieldProps } from "formik";
import { forgotPasswordValidationSchema } from "../../../utils/validation";
import { useEffect, useState } from "react";
import { forgotPassword } from "../../../services/authService";

const ForgotPassword = () => {
  const { email } = useParams();
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [showPassword, setShowPassword] = useState(false);
  const [isAvaliable, setIsAvaliable] = useState<boolean>(true);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      setIsAvaliable(false);
    }
  }, []);

  const handleSubmit = (
    values: { password: "" },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const data = {
      password: values.password,
      email: email!,
    };
    forgotPassword(data)
      .then((res) => {
        toast({
          title: "Reset password successful.",
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        formikHelpers.setSubmitting(false);
        navigate("/login");
      })
      .catch((error) => {
        toast({
          title: error.response.data,
          position: "top",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={BgSignUp}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
          Reset Password!
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "1rem" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          {isAvaliable ? (
            <>
              <FormControl mb="24px">
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  fontSize="sm"
                  type="text"
                  placeholder="Your email address"
                  size="lg"
                  value={email}
                  isDisabled
                />
              </FormControl>

              <Formik
                initialValues={{ password: "" }}
                validationSchema={forgotPasswordValidationSchema}
                onSubmit={handleSubmit}
              >
                {(formikProps) => (
                  <Form>
                    <Field name="password">
                      {({ field, form }: FieldProps<{ password: string }>) => (
                        <FormControl
                          mb="36px"
                          isInvalid={
                            !!(form.errors.password && form.touched.password)
                          }
                        >
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            New Password
                          </FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              borderRadius="15px"
                              fontSize="sm"
                              type={showPassword ? "text" : "password"}
                              placeholder="Your password"
                              size="lg"
                              value={field.value.password}
                            />
                            <InputRightElement
                              width="4.5rem"
                              height="100%"
                              cursor="pointer"
                            >
                              {showPassword ? (
                                <FiEye
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <FiEyeOff
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              )}
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.password as string}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      type="submit"
                      bg="teal.300"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      mt="20px"
                      isLoading={formikProps.isSubmitting}
                      _hover={{
                        bg: "teal.200",
                      }}
                      _active={{
                        bg: "teal.400",
                      }}
                    >
                      Reset Password
                    </Button>
                  </Form>
                )}
              </Formik>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColor} fontWeight="medium">
                  Already have an account?
                  <Link
                    color={titleColor}
                    as={RouterLink}
                    to={"/login"}
                    ms="5px"
                    href="#"
                    fontWeight="bold"
                  >
                    Login
                  </Link>
                </Text>
              </Flex>
            </>
          ) : (
            <>
              <Box
                minH="25rem"
                textAlign="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box fontSize="4rem" color="red.400">
                  <FiXCircle />
                </Box>

                <Text fontSize="3xl">Sorry, the current link is invalid.</Text>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
