import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import RequirementsList from "./List";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const Requirements: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.project-marketplace", path: "/project-marketplace" },
  ];

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" pb="0">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.project-marketplace")}
        </Text>
        <Text>{t("requirements.tab.list-sub-title")}</Text>
      </Box>
      <RequirementsList refreshKey={0}></RequirementsList>
    </Box>
  );
};

export default Requirements;
