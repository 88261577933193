import React from "react";
import { AddIcon } from "@chakra-ui/icons"; 

const AddButton = (props) => {
  let { addElement, addTo, hidden, styles } = props;
  return (
    <span hidden={hidden} title="add item" onClick={() => addElement(addTo)}>
      <span style={styles.addButton}><AddIcon /></span>
    </span>
  );
};

export default AddButton;
