import React from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  ModalOverlay,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { deleteRequirement } from "../../../services/requirementService";

interface DeleteFormProps {
  isOpen: boolean;
  requirementId: string;
  onOpen: () => void;
  onClose: () => void;
  onRefresh: () => void;
}

const DeleteRequirement: React.FC<DeleteFormProps> = ({
  isOpen,
  onOpen,
  onClose,
  onRefresh,
  requirementId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleDelete = () => {
    deleteRequirement(requirementId)
      .then((res) => {
        onRefresh();
        onClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch(() => {
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> {t("requirements.delete-modal.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t("requirements.delete-modal.content")}</ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} size="sm">
            {t("common.btn.cancel")}
          </Button>
          <Button colorScheme="red" size="sm" onClick={() => handleDelete()}>
            {t("common.btn.delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteRequirement;
