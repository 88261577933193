import { Box, Text, Button, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface NoticeProps {
  titleStr: string;
  btnStr: string;
}

const Notice: React.FC<NoticeProps> = ({ titleStr, btnStr }) => {
  return (
    <Box bg={"#fff"} p="1rem" mb="1rem" textAlign="center">
      <Text color={"gray.500"} mb={6}>
        {titleStr}
      </Text>
      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
      >
        <Link
          as={RouterLink}
          to={"/workbench/organization"}
          display="flex"
          alignItems="center"
          style={{ textDecoration: "none" }}
        >
          {btnStr}
        </Link>
      </Button>
    </Box>
  );
};

export default Notice;
