import { EditInfo } from "../models/organization";
import instance from "./interceptor";

export const organizationList = async () => {
  try {
    const response = await instance.get(`/api/organization`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationDetail = async (id: string) => {
  try {
    const response = await instance.get(`/api/organization/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationMemberList = async (id: string) => {
  try {
    const response = await instance.get(`/api/organization/${id}/users`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const organizationMemberListWithRole = async (id: string) => {
  try {
    const response = await instance.get(
      `/api/organization/${id}/UsersWithRole`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getInvitationInfo = async (id: string) => {
  try {
    const response = await instance.get(`/api/user/invitations/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const sendInvitation = async (data: { email: string }) => {
  try {
    const response = await instance.post(`/api/user/sendInvitation`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const setDefaultOrganization = async (organizationId: string) => {
  try {
    const response = await instance.post(
      `/api/user/organizations/${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteMember = async (data: EditInfo) => {
  try {
    const response = await instance.delete(`/api/organization/user`, { data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const updateMemberRole = async (data: EditInfo) => {
  try {
    const response = await instance.post(`/api/organization/SetUserRole`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};
